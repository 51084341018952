<template>
  <div>
      <div class="hz" v-for="item in ProjectList">
          <CommonProject :item="item"></CommonProject>
      </div>
  </div>
</template>

<script>
import CommonProject from "@/components/CommonProject.vue";

export default {
    name: "MenuOne",
    components: {
        CommonProject
    },
    props:['ProjectList'],
    data () {
        return {

        }
    },
    created() {

    },
    methods: {

    }
}
</script>

<style scoped>
.hz {
    padding-top: 54px;
    padding-bottom: 48px;
}
</style>
