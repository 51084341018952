<template>
  <div class="footerHZ">
      <footer>
          <div class="hz">
          <div class="container">

              <!-- 底部链接 -->
              <div class="FooterLink">
                  <div class="ZiXun">
                      <div class="sqzx">
                          <h3>售前咨询</h3>
                          <div class="zxxx">
                              <div class="dh">010-58090096</div>
                              <div class="dh">010-58090098</div>
                              <div class="yx">zjli@timace.com</div>
<!--                              <CommonButton :isShow="false" :text="text" :disabled="true"></CommonButton>-->
                          </div>
                      </div>
                      <div class="gzxd">
                          <img src="@/assets/image/qrcode.png" />
                          <div>
                              <h3>关注贤达</h3>
                              <a href="javascript:;"><span>贤达信息</span></a>
                              <router-link to="/join"><span>贤达招聘</span></router-link>
                              <router-link to="/join"><span>加入我们</span></router-link>
                          </div>
                      </div>
                  </div>
                  <div class="RelatedLinks">
                      <dl>
                          <dt>关于贤达</dt>
                          <dd>公司信息</dd>
                          <dd>子公司</dd>
                          <dd>办事处</dd>
                          <dd><router-link to="/news">新闻</router-link></dd>
<!--                          <dd>投资发展</dd>-->
                      </dl>
                  </div>
<!--                  <div class="RelatedLinks">-->
<!--                      <dl>-->
<!--                          <dt>友情链接</dt>-->
<!--                          <dd><a href="http://www.zhujipower.com/" target="_blank">浙江帕瓦新能源股份有限公司</a></dd>-->
<!--                          <dd><a href="https://www.highpowertech.com/" target="_blank">深圳豪鹏科技股份有限公司</a></dd>-->
<!--                          <dd><a href="https://www.gem.com.cn/" target="_blank">格林美股份有限公司</a></dd>-->
<!--                          <dd><a href="http://www.crimm.com.cn/" target="_blank">长沙矿冶院</a></dd>-->
<!--                          <dd><a href="https://www.kingview.com/" target="_blank">亚控科技</a></dd>-->
<!--                      </dl>-->
<!--                  </div>-->
                  <div class="RelatedLinks">
                      <dl>
                        <dt>解 决 方 案</dt>
                        <dd>智能工厂</dd>
                        <dd>智慧园区</dd>
                        <dd>智能物流</dd>
                      </dl>
                  </div>
              </div>

          </div>
          </div>

          <!-- 版权 -->
          <div class="copyrightHZ">
          <div class="copyright">
              <div class="container">
                  © 2023 xdsf.cc 版权所有。ICP证 :
                  <a href="http://beian.miit.gov.cn" target="_blank">京ICP备2023015416号-1</a>
              </div>
          </div>
          </div>

      </footer>
  </div>
</template>

<script>
import CommonButton from "@/components/CommonButton.vue";
export default {
    name: "CommonFooter",
    components: {
        CommonButton
    },
    data () {
        return {
            text: '在线咨询'
        }
    }
}
</script>

<style lang="less" scoped>

@media screen  and (max-width: 1281px) {
    footer {
        .container {
            padding-left: 0;
            padding-right: 0;
        }
    }
}
@media screen  and (max-width: 1140px) {
    footer {
        .FooterLink {
            flex-wrap: wrap;
        }
    }
}
@media screen  and (max-width: 972px) {
    footer {
        .FooterLink {
            flex-wrap: wrap;
            //justify-content: space-around !important;
            padding-left: 50px;
            padding-right: 50px;
        }
    }
}

.footerHZ {
}
footer {
    width: 100%;
    background-color: #f8f9fa;
    div.hz {
        background: url("@/assets/image/about.png") no-repeat 0 0/100% 100%;
        //max-width: 1914px;
        margin: 0 auto;
    }
    .FooterLink {
        padding-top: 93px;
        padding-bottom: 97px;
        display: flex;
        justify-content: space-between;
        .ZiXun {
            margin-left: 20px;
            margin-right: 20px;
            .sqzx {
                display: flex;
                justify-content: space-between;
                h3 {
                    font-weight: 400;
                    color: #fff;
                    margin: 0;
                    margin-right: 60px;
                    font-size: 30px;
                }
                .zxxx {
                    .dh {
                        font-size: 20px;
                      margin-top: 0;

                    }
                    .yx {
                        font-size: 20px;
                    }
                    div {
                        margin: 10px 0;
                        color: #fff;
                    }
                    a {
                        div {
                            color: #979797;
                            border: 1px solid #979797;
                            padding: 12px 30px;
                            font-size: 18px;
                            img {
                                margin-left: 15px;
                            }
                        }
                    }
                }
            }
            .gzxd {
                margin-top: 20px;
                padding: 16px 18px;
                background: rgba(0,0,0,.3);
                border-radius: 2px;
                display: flex;
                img {
                    width: 136px;
                    height: 136px;
                    border-radius: 4px;
                }
                div {
                    margin-left: 30px;
                    h3 {
                        font-size: 28px;
                        color: #fff;
                        margin: 16px 0 40px 0;
                    }
                    span {
                        margin-right: 28px;
                    }
                }
            }
        }
        .RelatedLinks {
            dl {
                margin-top: 0;
                margin-left: 20px;
                margin-right: 20px;
                dt {
                    font-size: 30px;
                    color: #fff;
                    margin-bottom: 30px;
                }
                dd {
                    font-size: 20px;
                    color: #fff;
                    margin: 0 0 26px 0;
                }
            }
        }
    }

    .copyrightHZ {
        width: 100%;
    }
    .copyright {
        //max-width: 1914px;
        margin: 0 auto;
        padding: 24px 0;
        background-color: rgba(36, 36, 36);
        color: #fff;
        font-size: 18px;
        .container {
            text-align: center;
        }
    }
}
</style>
