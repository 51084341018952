<template>
    <div>
        <div class="hz" v-for="item in ProjectList">
            <CommonProject :item="item"></CommonProject>
        </div>
    </div>
</template>

<script>
import CommonProject from "@/components/CommonProject.vue";
import { setStorage, getStorage } from "@/utils/storage";
export default {
    name: "MenuOne",
    components: {
        CommonProject
    },
    data () {
        return {
            ProjectList: getStorage('ProjectList') || []
        }
    },
    created() {
        let data = this.$route.query.ProjectList
        let arr = []
        data.forEach(item => {
            if (item.type === "2") {
                arr.push(item)
            }
        })
        console.log(arr,'222')
        this.ProjectList = arr
        if (this.$route.query.ProjectList[0].id) {
            setStorage('ProjectList', arr)
        }
    }
}
</script>

<style scoped>
.hz {
    padding-top: 54px;
    padding-bottom: 48px;
}
</style>
