<template>
  <div v-cloak>
    <!--        <t-skeleton animation="gradient" :loading="loading" :rowCol="rowCol" style="height: 830px">-->
    <!-- 轮播图 -->
    <div class="banner" v-show="isShow">

      <div class="container2">
        <!--            <t-skeleton animation="gradient" :loading="loading" style="height: 830px">-->

        <!--                <t-row class="t-skeleton-demo">-->

        <!--                        <section class="t-skeleton-demo-card">-->
        <!--                            <div class="content">-->
        <!--                                <t-skeleton :animation="animation.value"></t-skeleton>-->
        <!--                            </div>-->
        <!--                        </section>-->

        <!--                </t-row>-->
        <div class="banner-bjimg">
          <img src="../assets/image/banner-bjimg.png" alt="xdsf"/>
        </div>

        <div class="tdesign-demo-block--swiper" style="height: auto;overflow: hidden;">
          <t-swiper :duration="300" :interval="3000" :navigation="{ placement: 'outside' }">
            <t-swiper-item v-for="item in BannerList" :key="item.id">
              <div class="demo-item"><a :href="item.url" style="width: 100%;"><img ref="BannerImg" :src="item.pic"/></a>

              </div>
            </t-swiper-item>
          </t-swiper>

          <div class="DHT">
            <div class="container">
              <ul>
                <li v-for="item in BannerList" :key="item.id || 1">
                  <a :href="item.url">{{ item.title || "" }}</a>
                  <div></div>
                </li>
              </ul>
            </div>
          </div>
        </div>


        <!--
        <el-carousel class="el-carousel" :height="heightNumber">
            <el-carousel-item>
                <h3 class="small">
                    <img ref="BannerImg" src="../assets/image/banner1.png" />
                </h3>
            </el-carousel-item>
            <el-carousel-item>
                <h3 class="small">
                    <img src="../assets/image/banner2.png" />
                </h3>
            </el-carousel-item>
            <el-carousel-item>
                <h3 class="small">
                    <img src="../assets/image/banner3.png" />
                </h3>
            </el-carousel-item>
            <el-carousel-item>
                <h3 class="small">
                    <img src="../assets/image/banner4.png" />
                </h3>
            </el-carousel-item>
        </el-carousel>
        -->


      </div>




    </div>
    <!--        </t-skeleton>-->

    <!-- 轮播图下方链接 -->
    <div class="page-linkHZ">
      <div class="page-link" data-aos="fade-up">
        <div class="container">
          <router-link v-for="(item,i) in PageLink" :key="i" :to="{path:tolink[i],query:{item:item.id}}">
            <div class="xlj">
              <div class="anniu">
                <div>{{ item.type }}</div>
                <p>MORE</p>
                <div><img src="../assets/image/yjt.png" alt=""></div>
              </div>
              <p class="wz1">{{ item.title || "" }}</p>
              <p class="wz2">{{ item.remark || "" }}</p>
            </div>
          </router-link>
          <!--                <router-link :to="{path:'/newsdetail',query:{item2}}">-->
          <!--                    <div class="xlj">-->
          <!--                        <div class="anniu">-->
          <!--                            <div>行业新闻</div>-->
          <!--                            <p>MORE</p>-->
          <!--                            <div><img src="../assets/image/yjt.png" alt=""></div>-->
          <!--                        </div>-->
          <!--                        <p class="wz1">{{ PageLink[1].title || "" }}</p>-->
          <!--                        <p class="wz2">{{ PageLink[1].remark || "" }}</p>-->
          <!--                    </div>-->
          <!--                </router-link>-->
          <!--                <router-link :to="{path:'/projectdetail',query:{item3}}">-->
          <!--                    <div class="xlj">-->
          <!--                        <div class="anniu">-->
          <!--                            <div>项目案例</div>-->
          <!--                            <p>MORE</p>-->
          <!--                            <div><img src="../assets/image/yjt.png" alt=""></div>-->
          <!--                        </div>-->
          <!--                        <p class="wz1">{{ PageLink[2].title || "" }}</p>-->
          <!--                        <p class="wz2">{{ PageLink[2].remark || "" }}</p>-->
          <!--                    </div>-->
          <!--                </router-link>-->
        </div>
      </div>
    </div>

    <!-- 关于贤达 -->
    <div class="about-xdHZ">
      <div class="about-xd">
        <div class="hz">
          <div class="container">
            <div class="wz" data-aos="fade-up">
              <h3>关于贤达</h3>
              <p>
                北京贤达四方科技有限公司创建于1998年，一直致力于计算机系统集成、网络安全、企业信息化软硬件服务解决方案的提供与实施。随着大数据、云计算、智能技术的发展，公司业务也不断扩展到智慧企业领域，打造了一支能为企业提供全生命周期数字化转型服务的专业技术团队。</p>
              <p class="p2">
                公司在长期的发展过程中，坚持以诚信为本，不断创新，开拓进取，努力给每一个客户创造最大价值。同时坚持以人为本，用爱经营，关注每一位员工的持续成长，追求全体员工物质与精神的双幸福。</p>
              <CommonButton :isShow="false" :links="'/contact'" :text="'联系我们'"></CommonButton>
            </div>
            <div class="tp" data-aos="fade-up">
              <img :src="img"/>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 加入我们 -->
    <div class="join-usHZ">
      <div class="join-us">
        <div class="hz">
          <div class="container">
            <div class="wz" data-aos="fade-up">
              <h3>加入贤达</h3>
              <p>BE YOUNG,我们一直在路上</p>
              <div class="an-nio">
                <CommonButton :isShow="true" :links="'/join'" :text="'招贤纳士'"></CommonButton>
              </div>
            </div>
            <div class="tp" data-aos="fade-up">
              <img src="../assets/image/joinus.png"/>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import CommonButton from "@/components/CommonButton.vue";
import {getBannerList} from "@/api/banner";
import {getNewsList} from "@/api/news";
import {getProjectList} from "@/api/project";

export default {
  name: "HomePage",
  components: {
    CommonButton
  },
  data() {
    return {
      type: ['公司动态', '行业新闻', '项目案例'],
      tolink: ['/newsdetail', '/newsdetail', '/projectdetail'],
      // isShow: false,
      BannerList: [],
      heightNumber: '820px',
      img: require('../assets/image/xianda.jpg'),
      PageLink: [],
      item: [],
      DhtList: [
        {
          id: 1,
          title: '产品数字化C2M',
          link: '#',
        }, {
          id: 2,
          title: '财务数字化GMC',
          link: '#',
        }, {
          id: 3,
          title: '泛ERP',
          link: '#',
        }, {
          id: 4,
          title: 'ITO',
          link: '#',
        }, {
          id: 5,
          title: '数字平台体系',
          link: '#',
        }, {
          id: 6,
          title: '工程项目',
          link: '#',
        },
      ],
      loading: true,
      rowCol: [
        {
          type: 'rect',
          content: 'image',
          width: '100%',
          height: '100%',
        },
      ],
    }
  },
  created() {
    this.getBannerList()
    this.getNewsList()
    this.getProjectList()
    this.isShow = true
    // console.log(this.PageLink,'---------------')

  },
  methods: {

    // 请求banner图
    async getBannerList() {
      let res = await getBannerList()
      // console.log(res)
      if (res.data.code === 200) {
        this.BannerList = res.data.rows
        this.isShow = true
        // this.loading = false
        // console.log(res.data.rows,'===========')
      }
    },

    // 请求新闻列表
    async getNewsList() {
      let res = await getNewsList()
      // console.log(res, 'newsnewsnews')
      let data = res.data.rows
      let arr1 = [] // 公司动态
      let arr2 = [] // 行业新闻
      if (res.data.code === 200) {
        data.forEach((item) => {
          if (item.type === "1") {
            item.type = '公司动态'
            arr1.push(item)
          } else {
            item.type = '行业新闻'
            arr2.push(item)
          }
        })
        this.PageLink.push(arr1.pop())
        this.PageLink.push(arr2.pop())
      }
    },

    // 请求项目案例列表
    async getProjectList() {
      let res = await getProjectList()
      console.log(res.data.rows, 'project')
      let data = res.data.rows
      data[0].type = '项目案例'
      this.PageLink.push(data[0])
    },

    // chuanId(Id,i) {
    //     this.$router.push({
    //         name: this.tolink[i],
    //         query: { item: Id },//这种方式的传参必须要有路由的别名name
    //     });
    // },

  },
  mounted() {
    // window.onresize = this.myfunction()
  }
}
</script>

<style lang="less">


.DHT {
  width: 100%;
  max-width: 100%;
  //background: #070F20;
  opacity: 0.74;
  position: absolute;
  left: 0;
  bottom: 4px;
  z-index: 2;

  .container {
    max-width: 100%;
    margin: 0 auto;
    background: #070F20;

    ul {
      display: flex;
      justify-content: center;
      max-width: 1920px;
      align-items: center;
      margin: 0 auto;

      li {
        width: 30%;
        display: flex;
        align-items: center;

        a {
          padding: 38px 0;
          width: 100%;
          font-size: 20px;
          color: #FFFFFF;
        }

        div {
          margin: 0 30px;
          width: 1px;
          height: 38px;
          background: #525A68;
        }
      }

      li:last-child div {
        display: none;
      }
    }
  }
}

// 加入我们
.join-usHZ {
}

.join-us {
  background-color: #f8f9fa;

  div.hz {
    background: url("../assets/image/join.png") no-repeat 0 0/100% 100%;
    //max-width: 1914px;
    margin: 0 auto;
  }

  .container {
    display: flex;
    justify-content: space-between;
    padding: 79px 0;

    .wz {
      h3 {
        color: #000000;
        font-size: 44px;
      }

      p {
        color: #000000;
        font-size: 20px;
        margin-top: 14px;
        margin-bottom: 40px;
      }

      .an-nio {
        display: flex;

        .k-b {
          width: 28px;
        }
      }
    }

    .tp {
    }
  }
}

// 关于贤达
.about-xdHZ {
  width: 100%;
  background-color: #f8f9fa;
}

@media screen  and (max-width: 1281px) {
  .banner {
    .container {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .join-us .container {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media screen  and (max-width: 1000px) {
  .about-xd {
    .container {
      flex-direction: column;

      .wz {
        p {
          padding-right: 0 !important;
        }
      }
    }
  }
}

.about-xd {
  //background-color: #fff;
  width: 100%;

  div.hz {
    background: url("../assets/image/erp-bg.png") no-repeat 0 0/100% 100%;
    //max-width: 1914px;
    margin: 0 auto;
  }

  .container {
    display: flex;
    justify-content: center;
    padding-top: 93px;
    padding-bottom: 107px;

    .wz {
      h3 {
        font-size: 40px;
        color: #FFFFFF;
        margin-bottom: 18px;
      }

      p {
        line-height: 34px;
        color: #FFFFFF;
        font-size: 20px;
        margin: 0;
        padding-right: 55px;
        display: inline-block;
        text-indent: 2em;
      }

      .p2 {
        margin-bottom: 33px;
      }
    }

    .tp {
      img {
        //width: 658px;
        height: 392px;
        margin-top: 28px;
      }
    }
  }

}

// 轮播图下方小链接
.page-linkHZ {
  background-color: #f8f9fa;
}

.page-link {
  //max-width: 1914px;
  margin: 0 auto;
  padding-top: 45px;
  padding-bottom: 50px;
  background-color: #fff;

  .container {
    //margin-top: 45px;
    //margin-bottom: 50px;
    display: flex;
    justify-content: space-between;

    a {
      .xlj {
        max-width: 380px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .anniu {
          display: flex;
          align-items: center;

          div:nth-child(1) {
            color: #4F4F4F;
            font-size: 20px;
            background: #EAF4F5;
            border-radius: 4px;
            display: inline-block;
            padding: 10px 26px;
          }

          p {
            color: #4F4F4F;
            font-size: 18px;
            margin: 0 7px 0 20px;
          }

          div:nth-child(3) {
            width: 20px;
            height: 20px;
            background: #EAF4F5;
            border-radius: 22px;
            text-align: center;
          }
        }

        .wz1 {
          color: #4F4F4F;
          font-size: 22px;
          margin: 12px 0;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
        }

        .wz2 {
          color: #A4A4A4;
          font-size: 18px;
          margin: 0;
          max-width: 380px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;

        }
      }
    }

    a:nth-child(2) {
      .xlj {
        .anniu {
          div:nth-child(1) {
            background: #F3F5EA;
          }

          div:nth-child(3) {
            background: #F3F5EA;
          }
        }
      }
    }

    a:nth-child(3) {
      .xlj {
        .anniu {
          div:nth-child(1) {
            background: #EAEDFD;
          }

          div:nth-child(3) {
            background: #EAEDFD;
          }
        }
      }
    }
  }
}

// 轮播图
//.el-carousel__item h3 {
//    color: #475669;
//    font-size: 14px;
//    /*opacity: 0.75;*/
//    line-height: 150px;
//    margin: 0;
//}
.banner {
  position: relative;
  width: 100%;
  background-color: #f8f9fa;
  max-height: 100vh;
  overflow: hidden;

  .container2 {
    //max-width: 1914px;
    width: 100%;
    //height: 99vh;
    margin: 0 auto;

    .banner-bjimg {
      position: absolute;
      z-index: 999;
      margin-top: 10%;
      transform: translateX(36%) translateY(90%);
      //transform: translateY(50%);
      img {
        width: 60%;
      }
    }
    .t-swiper {
      height: auto;
    }
    .t-swiper__content {
      .t-swiper__container__item {
        .demo-item {
          img {
            width: 100%;
          }
        }
      }
    }

    .t-swiper--outside .t-swiper__navigation {
      bottom: 128px !important;
      z-index: 9999 !important;
    }

    .t-swiper--outside .t-swiper__navigation .t-swiper__navigation-item span {
      background-color: rgba(333, 333, 333, 8);
    }

    .t-swiper--outside .t-swiper__navigation .t-swiper__navigation-item.t-is-active > span {
      background-color: rgba(0, 60, 171, 8) !important;
    }
  }
}

[v-cloak] {
  display: none !important;
}

</style>
