<template>
  <div>
    <CommonHead :Top="Top" :scrollisshow="scrollisshow"></CommonHead>

    <!-- 页面组件出口 -->
    <router-view :key="$route.fullPath"></router-view>

    <common-footer></common-footer>

    <div class="go-top" v-show="isShow" @click="GoTop">
      <i class="iconfont icon-icon"></i>
    </div>
  </div>
</template>

<script>
import CommonHead from "@/components/CommonHead.vue";
import CommonFooter from "@/components/CommonFooter.vue";
export default {
  name: "CommonIndex",
  data() {
    return {
      Top: null,
      isShow: false,
      // 滚动前，滚动条距文档顶部的距离
      oldScrollTop: 0,
      scrollisshow: true,
    };
  },
  components: {
    CommonHead,
    CommonFooter,
  },
  mounted() {
    // 监听页面滚动事件
    window.addEventListener("scroll", this.scrolling);
  },
  methods: {
    scrolling() {
      // 滚动条距文档顶部的距离
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      // 滚动条滚动的距离
      let scrollStep = scrollTop - this.oldScrollTop;
      // 更新——滚动前，滚动条距文档顶部的距离
      this.oldScrollTop = scrollTop;
      if (scrollStep <= 0 || scrollTop === null) {
        // console.log("滚动条向上滚动了！")
        this.scrollisshow = true;
      } else if (scrollTop >= 10) {
        this.scrollisshow = false;
      }

      this.Top = Math.floor(window.scrollY);
      // console.log(window.scrollY)
      if (this.Top >= 200) {
        this.isShow = true;
      } else {
        this.isShow = false;
      }
    },
    GoTop() {
      // console.log('111')
      document.documentElement.scrollTop = 0;
    },
  },
};
</script>

<style>
.go-top {
  z-index: 9999;
  position: fixed;
  right: 100px;
  bottom: 100px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0px 0px 15px 3px rgba(0, 0, 0, 0.3);
  text-align: center;
  line-height: 50px;
  color: #336699;

}

.iconfont {
  font-size: 20px;
}

div.dahezi {
  width: 100%;
  background-color: #f8f9fa;
}
div.xiaohezi {
  /*max-width: 1920px;*/
  margin: 0 auto;
  background-color: #fff;
}
</style>
