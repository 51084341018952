<template>
  <div>

      <div class="cooperate">
          <div class="hz">
          <div class="container">
              <div>
                  <h3>合作伙伴</h3>
                  <span>P A R T N E R S</span>
              </div>
          </div>
          </div>
      </div>

      <div class="dahezi">
       <div class="hzgs-logo xiaohezi">
           <div class="hz">
           <div class="container">

              <div class="hzhb" data-aos="fade-up">
                   <h3>合作伙伴</h3>
                   <span>P A R T N E R S</span>
               </div>

               <ul data-aos="fade-up" >
                   <li v-for="(item,i) in imgList" :key="i" style="margin: 18px 44px;">
                       <a href="#">
                           <img :src="item" />
                       </a>
                   </li>
               </ul>

           </div>
           </div>
       </div>
      </div>

  </div>
</template>

<script>
export default {
    name: "CooperatePage",
    data () {
        return {
            imgList: [
                require('../assets/image/hzhblogo/01.png'),
                require('../assets/image/hzhblogo/02.png'),
                require('../assets/image/hzhblogo/03.png'),
                require('../assets/image/hzhblogo/04.png'),
                require('../assets/image/hzhblogo/05.png'),
                require('../assets/image/hzhblogo/06.png'),
                require('../assets/image/hzhblogo/07.png'),
                require('../assets/image/hzhblogo/08.png'),
                require('../assets/image/hzhblogo/09.png'),
                require('../assets/image/hzhblogo/10.png'),
                require('../assets/image/hzhblogo/11.png'),
                require('../assets/image/hzhblogo/12.png'),
                require('../assets/image/hzhblogo/13.png'),
                require('../assets/image/hzhblogo/14.png'),
                require('../assets/image/hzhblogo/15.png'),
                require('../assets/image/hzhblogo/16.png'),
                require('../assets/image/hzhblogo/17.png'),
                require('../assets/image/hzhblogo/18.png'),
                require('../assets/image/hzhblogo/19.png'),
                require('../assets/image/hzhblogo/20.png'),
            ]
        }
    },
}
</script>

<style lang="less" scoped>

@media screen  and (max-width: 1243px) {
  .hzgs-logo {
    .hz {
      .container {
        ul {
          justify-content: center;
          li {
            margin-bottom: 36px;
            margin-right: 88px;
          }
          li:nth-child(5n) {
            margin-right: 88px;
          }
          li:nth-child(4n) {
            margin-right: 0 !important;
          }
        }
      }
    }
  }
}
@media screen  and (max-width: 985.3px) {
  .hzgs-logo {
    .hz {
      .container {
        ul {
          justify-content: center;
          li {
            margin-bottom: 36px;
            margin-right: 88px;
          }
          li:nth-child(5n) {
            margin-right: 88px;
          }
          li:nth-child(4n) {
            margin-right: 88px !important;
          }
          li:nth-child(3n) {
            margin-right: 0 !important;
          }
        }
      }
    }
  }
}
@media screen  and (max-width: 728px) {
  .hzgs-logo {
    .hz {
      .container {
        ul {
          justify-content: center;
          min-width: 472px;
          li {
            margin-bottom: 36px;
            margin-right: 88px;
          }
          li:nth-child(5n) {
            margin-right: 88px;
          }
          li:nth-child(4n) {
            margin-right: 88px !important;
          }
          li:nth-child(3n) {
            margin-right: 88px !important;
          }
          li:nth-child(2n) {
            margin-right: 0 !important;
          }
        }
      }
    }
  }
}

.hzgs-logo {
  width: 100%;
    background: url("../assets/image/hzgz-bj.png") no-repeat 0 0/100% 100%;
  div.hz {
    //max-width: 1914px;
    margin: 0 auto;
  }
  .container {
    padding-bottom: 76px;
    .hzhb {
      text-align: center;
      padding-top: 50px;
      padding-bottom: 50px;
      h3 {
        color: #454545;
        font-size: 30px;
        margin-bottom: 10px;
        font-weight: 400;
      }
      span {
        font-size: 18px;
        color: #DEDEDE;
      }
    }
    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      li {
        a {
          img {
            width: 169px;
            height: 54px;
            box-shadow: 1px 4px 10px 0px rgba(214,214,214,0.5);
          }
        }
      }
      li:nth-child(5n) {
        margin-right: 0;
      }
    }
  }

}


.cooperate {
    width: 100%;
  background-color: #f8f9fa;
  div.hz {
    //max-width: 1914px;
    margin: 0 auto;
    background: url("../assets/image/hzhb-bj.png") no-repeat 0 0/100% 100%;
  }
    .container {
      padding-top: 180px;
      padding-bottom: 215px;
        div {
            h3 {
                font-size: 30px;
                color: #FFFFFF;
            }
            span {
                display: inline-block;
                margin-top: 10px;
                font-size: 20px;
                color: #FFFFFF;
            }
        }
    }
}

</style>
