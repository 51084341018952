<template>
  <div>

      <div class="recruit">
          <div class="hz">
          <div class="container">
              <div class="wz">
                  <h3>招聘信息</h3>
                  <span>I n f o&nbsp;&nbsp;r m a t i o n</span>
              </div>
          </div>
          </div>
      </div>

      <div class="dahezi">
      <div class="zhaopinxinxi xiaohezi">
          <div class="container">

              <div class="zuo" data-aos="fade-up">
<!--                  <div class="sousuo">-->
<!--                      <img src="../assets/image/zpxx-search.png" />-->
<!--                      <input type="text" placeholder="搜索职位关键字" />-->
<!--                      <button>搜索职位</button>-->
<!--                  </div>-->
                  <div class="qbzw">全部职位 (共{{ RecruitList.length }}个)</div>
                  <div class="fgx"></div>

                  <div class="jutizhiwei" v-for="(item,i) in RecruitList" :key="i">
                      <div @click="zhankai(i)">
                      <div class="di-yi">
                          <h3>{{ item.title }}</h3>
                          <span>{{ item.salary }}</span>
                      </div>
                      <div class="di-er">
                          <div>
                              <span>社会招聘</span>
                              <span>|</span>
                              <span>全职</span>
                          </div>
                          <span>{{ item.createTime }} 发布</span>
                      </div>
                      </div>
                      <div class="di-san" v-show="isShow === i">
                          <div class="neirong">
                              <div v-html="item.content"></div>
<!--                              <p>任职资格</p>-->
<!--                              <p>1、机械、机电、信息、计算机工程等相关专业，专科以上学历；</p>-->
<!--                              <p> 2、3年以上制造业企业从事设计、工艺、制造信息化相关工作，或者3年以上从事MES系统\自动化生产线\智能物流\智能工厂技术-->
<!--                                  咨询支持工作，有过智能工厂规划工作经验者优先；</p>-->
<!--                              <p> 3、较强的学习能力，新环境的适应能力，能承受一定的工作压力；</p>-->
<!--                              <p> 4、思维清晰，具有极强的语言表达能力，工作细致认真；</p>-->
<!--                              <p> 5、具有较强的流程梳理、分析、优化能力；</p>-->
<!--                              <p> 6、善于与人沟通，具备良好的解决问题技巧及沟通、组织、协调能力。</p>-->
<!--                              <p>任职资格</p>-->
<!--                              <p>1、机械、机电、信息、计算机工程等相关专业，专科以上学历；</p>-->
<!--                              <p> 2、3年以上制造业企业从事设计、工艺、制造信息化相关工作，或者3年以上从事MES系统\自动化生产线\智能物流\智能工厂技术-->
<!--                                  咨询支持工作，有过智能工厂规划工作经验者优先；</p>-->
<!--                              <p> 3、较强的学习能力，新环境的适应能力，能承受一定的工作压力；</p>-->
<!--                              <p> 4、思维清晰，具有极强的语言表达能力，工作细致认真；</p>-->
<!--                              <p> 5、具有较强的流程梳理、分析、优化能力；</p>-->
<!--                              <p> 6、善于与人沟通，具备良好的解决问题技巧及沟通、组织、协调能力。</p>-->
                          </div>
                          <div class="anniu">
<!--                              <div>立即投递</div>-->
<!--                              <div>-->
<!--                                  <img src="../assets/image/shoucang.png" />-->
<!--                                  收藏-->
<!--                              </div>-->
                              <div>
                                <router-link :to="{path:'/recruitdetail',query:{item:item.id}}">
                                  查看详情
                                </router-link>
                              </div>
                          </div>
<!--                          <div class="shouqi">-->
<!--                              <img src="../assets/image/shouqi.png" />-->
<!--                          </div>-->
                      </div>
                  </div>

              </div>

              <div class="you"></div>

          </div>
      </div>
      </div>

  </div>
</template>

<script>
import { getRecruitList } from "@/api/recruit";
import {getStorage, setStorage} from "@/utils/storage";

export default {
    name: "RecruitPage",
    data () {
        return {
            isShow: false,
            RecruitList: []
        }
    },
    created() {
        let type = this.$route.query.type
        // console.log(type,'-----------------------')
        this.getRecruitList(type)
    },
    methods: {
        async getRecruitList (type) {
            let res = await getRecruitList()
            // console.log(res.data.rows,'---------------------------')
            let data = res.data.rows
            let arr1 = []
            data.forEach((item)=>{
                if(item.type === ''+type) {
                    arr1.push(item)
                }
            })
            // console.log(arr1,'----------------------')
            this.RecruitList = arr1
        },
        zhankai (i) {
            this.isShow = i
        },

    }
}
</script>

<style lang="less" scoped>

.zhaopinxinxi {
    .container {
        padding-top: 62px;
        padding-bottom: 70px;
        .zuo {
            width: 800px;
            .sousuo {
                height: 60px;
                display: flex;
                align-items: center;
                border-radius: 30px;
                border: 1px solid #EEEEEE;
                box-sizing: border-box;
                img {
                    width: 27px;
                    height: 27px;
                    margin-left: 40px;
                    margin-right: 15px;
                }
                input {
                    outline: none;
                    height: 56px;
                    flex: 1;
                    border: 0;
                    font-size: 20px;
                    color: #CFCFCF;
                }
                button {
                    font-size: 20px;
                    color: #FFFFFF;
                    width: 200px;
                    height: 60px;
                    margin: 0;
                    padding: 0;
                    border: 0;
                    background: #407BFF;
                    border-radius: 0px 30px 30px 0px;
                }
            }
            .qbzw {
                font-size: 20px;
                color: #252525;
                margin-top: 53px;
                margin-bottom: 26px;
                padding-left: 40px;
            }
            .fgx {
                height: 1px;
                background: #F0F0F0;
            }

            .jutizhiwei {
                padding: 40px 20px 0 40px;
                border-bottom: 1.4px solid #f0f0f0;
                .di-yi{
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 16px;
                    h3{
                        font-size: 26px;
                        color: #252525;
                        font-weight: 500;
                    }
                    span{
                        font-size: 26px;
                        color: #FF9B0A;
                    }
                }
                .di-er{
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 45px;
                    div {
                        span {
                            font-size: 16px;
                            color: #252525;
                        }
                        span:nth-child(2) {
                            margin: 0 24px;
                        }
                    }
                }
                .di-san{
                    transition: .3s ease-in-out;
                    .neirong {
                        padding: 30px 40px;
                        background: #FAFAFA;
                        p {
                            font-size: 16px;
                            color: #575757;
                            line-height: 26px;
                        }
                    }
                    .anniu {
                        display: flex;
                        background: #FAFAFA;
                        padding: 0 40px 29px 40px;
                        div:nth-child(1){
                            padding: 10px 38px;
                            background: #407BFF;
                            border-radius: 4px;
                            color: #FFFFFF;
                            font-size: 16px;
                            margin-right: 34px;
                        }
                        div:nth-child(2){
                            padding: 10px 20px;
                            border: 1px solid #E9E9E9;
                            border-radius: 4px;
                            box-sizing: border-box;
                            font-size: 16px;
                            color: #575757;
                            img {
                                margin-right: 12px;
                                vertical-align: middle;
                            }
                        }
                        div:nth-child(3) a{
                            color: #575757;
                            line-height: 44px;
                            margin-left: 310px;
                        }
                        div:nth-child(3) a:hover {
                            color: #407BFF;
                        }
                    }
                    .shouqi {
                        text-align: center;
                        padding: 20px 0;
                    }
                }
            }

        }
        .you {

        }
    }
}

.recruit {
    width: 100%;
  background-color: #f8f9fa;
  div.hz {
    background: url("../assets/image/zpxx-bj.png") no-repeat 0 0/100% 110%;
    max-width: 1914px;
    margin: 0 auto;
  }
    .container {
        padding-top: 180px;
        padding-bottom: 215px;
        .wz {
            h3 {
                color: #575757;
                font-size: 30px;
            }
            span {
                display: inline-block;
                margin-top: 10px;
                font-size: 20px;
                color: #353535;
            }
        }
    }
}

</style>
