<template>
  <div>
    <div class="hezihezi">
      <header
        :class="[
          $route.meta.isColor ? 'bjcolor2' : 'bjcolor1',
          scrollisshow ? 'scrollisshow2' : 'scrollisshow1',
        ]"
      >
        <nav class="container">
          <!-- logo -->
          <router-link to="/home">
            <h1>
              <img
                v-show="!$route.meta.isColor"
                src="@/assets/image/logo.png"
                alt="贤达四方"
              />
              <img
                v-show="$route.meta.isColor"
                src="@/assets/image/logo2.png"
                alt="贤达四方"
              />
            </h1>
          </router-link>

          <!-- 导航栏 -->

          <el-menu
            :default-active="activeIndex"
            class="el-menu-demo"
            mode="horizontal"
            @select="handleSelect"
            background-color="transparent"
            :text-color="$route.meta.isColor ? '#000' : '#fff'"
            router
          >
            <el-menu-item index="/home">首页</el-menu-item>
            <el-menu-item index="/XWDT">
              <div
                @click.stop="fn"
                class="guanyuwomen"
                @mouseover.stop="isShowMenuFn_XW1"
                @mouseout.stop="isShowMenuFn_XW2"
              >
                新闻动态
                <div
                  v-show="isShowMenu_XW"
                  class="erjicaidan"
                  :class="$route.meta.isColor ? 'erjiColor2' : 'erjiColor1'"
                >
                  <router-link to="/news">
                    <div>公司动态</div>
                  </router-link>
                  <div class="fgx"></div>
                  <router-link :to="{ path: '/news', query: { type: 'HYXW' } }">
                    <div>行业新闻</div>
                  </router-link>
                </div>
              </div>
            </el-menu-item>
            <el-menu-item index="/XMAL">
              <div
                @click.stop="fn"
                class="guanyuwomen"
                @mouseover.stop="isShowMenuFn_XM1"
                @mouseout.stop="isShowMenuFn_XM2"
              >
                项目案例
                <div
                  v-show="isShowMenu_XM"
                  class="erjicaidan"
                  :class="$route.meta.isColor ? 'erjiColor2' : 'erjiColor1'"
                >
                  <router-link to="/project">
                    <div>有色加工</div>
                  </router-link>
                  <div class="fgx"></div>
                  <router-link to="/project">
                    <div>有色冶炼</div>
                  </router-link>
                  <div class="fgx"></div>
                  <router-link to="/project">
                    <div>锂电材料</div>
                  </router-link>
                  <div class="fgx"></div>
                  <router-link to="/project">
                    <div>其他</div>
                  </router-link>
                </div>
              </div>
            </el-menu-item>
            <el-menu-item index="/CPYJJFA">
              <div
                @click.stop="fn"
                class="guanyuwomen"
                @mouseover.stop="isShowMenuFn_CP1"
                @mouseout.stop="isShowMenuFn_CP2"
              >
                产品与解决方案
                <div
                  v-show="isShowMenu_CP"
                  class="erjicaidan erjicaidan_cp"
                  :class="$route.meta.isColor ? 'erjiColor2' : 'erjiColor1'"
                >
                  <router-link to="/product">
                    <div>智能工厂</div>
                  </router-link>
                  <div class="fgx"></div>
                  <router-link
                    :to="{ path: '/product', query: { type: 'ZHYQ' } }"
                  >
                    <div>智慧园区</div>
                  </router-link>
                  <div class="fgx"></div>
                  <router-link
                    :to="{ path: '/product', query: { type: 'ZNWL' } }"
                  >
                    <div>智能物流</div>
                  </router-link>
                </div>
              </div>
            </el-menu-item>
            <el-menu-item index="/cooperate">合作伙伴</el-menu-item>
            <el-menu-item index="/all">
              <div
                @click.stop="fn"
                class="guanyuwomen"
                @mouseover.stop="isShowMenuFn_GY1"
                @mouseout.stop="isShowMenuFn_GY2"
              >
                关于我们
                <div
                  v-show="isShowMenu_GY"
                  class="erjicaidan"
                  :class="$route.meta.isColor ? 'erjiColor2' : 'erjiColor1'"
                >
                  <router-link to="/contact">
                    <div>联系我们</div>
                  </router-link>
                  <div class="fgx"></div>
                  <router-link to="/join">
                    <div>加入我们</div>
                  </router-link>
                </div>
              </div>
            </el-menu-item>

            <!--                  <el-submenu index="/contact">-->
            <!--                      <template slot="title">关于我们</template>-->
            <!--                      <el-menu-item index="/contact">联系我们</el-menu-item>-->
            <!--                      <el-menu-item index="/join">加入我们</el-menu-item>-->
            <!--                  </el-submenu>-->
          </el-menu>

          <!--              <div class="box">-->
          <!--                  <t-head-menu v-show="$route.meta.isColor" defaultValue="2-1" expandType="popup">-->
          <!--                      <t-submenu value="1">-->
          <!--                          <template #title>-->
          <!--                              <span>菜单1</span>-->
          <!--                          </template>-->
          <!--                          <t-submenu value="1-0" title="子菜单1-1">-->
          <!--                              <t-menu-item value="1-1-1">子菜单1-1-1</t-menu-item>-->
          <!--                              <t-menu-item value="1-1-2">子菜单1-1-2</t-menu-item>-->
          <!--                              <t-menu-item value="1-1-3">子菜单1-1-3</t-menu-item>-->
          <!--                          </t-submenu>-->
          <!--                          <t-menu-item value="1-2">子菜单1-2</t-menu-item>-->
          <!--                          <t-menu-item value="1-3">子菜单1-3</t-menu-item>-->
          <!--                          <t-menu-item value="1-4">子菜单1-4</t-menu-item>-->
          <!--                          <t-submenu value="1-5" title="子菜单1-5">-->
          <!--                              <t-menu-item value="1-5-1">子菜单1-5-1</t-menu-item>-->
          <!--                              <t-menu-item value="1-5-2">子菜单1-5-2</t-menu-item>-->
          <!--                              <t-menu-item value="1-5-3">子菜单1-5-3</t-menu-item>-->
          <!--                          </t-submenu>-->
          <!--                      </t-submenu>-->
          <!--                      <t-submenu value="2" title="菜单2">-->
          <!--                          <t-menu-item value="2-1">子菜单2-1</t-menu-item>-->
          <!--                          <t-menu-item value="2-2">子菜单2-2</t-menu-item>-->
          <!--                          <t-menu-item value="2-3">子菜单2-3</t-menu-item>-->
          <!--                      </t-submenu>-->
          <!--                  </t-head-menu>-->

          <!--                  <t-head-menu v-show="!$route.meta.isColor" defaultValue="2-1" theme="dark" expandType="popup" style="margin-top: 24px">-->
          <!--                      <t-submenu value="1">-->
          <!--                          <template #title>-->
          <!--                              <span>菜单1</span>-->
          <!--                          </template>-->
          <!--                          <t-menu-item value="1-1">子菜单1-1</t-menu-item>-->
          <!--                          <t-menu-item value="1-2">子菜单1-2</t-menu-item>-->
          <!--                          <t-menu-item value="1-3">子菜单1-3</t-menu-item>-->
          <!--                      </t-submenu>-->
          <!--                      <t-submenu-->
          <!--                          value="2"-->
          <!--                          :popup-props="{-->
          <!--                          overlayClassName: 'custom-popup-class',-->
          <!--                          }"-->
          <!--                      >-->
          <!--                          <template #title>-->
          <!--                              <span>菜单2</span>-->
          <!--                          </template>-->
          <!--                          <t-menu-item value="2-1">子菜单2-1</t-menu-item>-->
          <!--                          <t-menu-item value="2-2">子菜单2-2</t-menu-item>-->
          <!--                          <t-menu-item value="2-3">子菜单2-3</t-menu-item>-->
          <!--                      </t-submenu>-->
          <!--                  </t-head-menu>-->
          <!--              </div>-->
        </nav>
      </header>
    </div>
  </div>
</template>

<script>
export default {
  name: "CommonHead",
  props: {
    Top: {
      type: Number,
    },
    scrollisshow: {
      type: Boolean,
    },
  },
  data() {
    return {
      activeIndex: "",
      isShow: true,
      isShowMenu_XW: false,
      isShowMenu_GY: false,
      isShowMenu_XM: false,
      isShowMenu_CP: false,
      // isColor: false, // 默认为白色
    };
  },
  created() {
    // console.log(this.$route.meta)
  },
  methods: {
    handleSelect(key, keyPath) {
      // console.log(key, keyPath);
    },
    getPath() {
      // console.log(this .$route.path, '//////////////////////')
      switch (this.$route.path) {
        case "/home":
          this.activeIndex = this.$route.path;
          break;
        case "/news":
          this.activeIndex = "/XWDT";
          break;
        case "/project":
          this.activeIndex = "/XMAL";
          break;
        case "/product":
          this.activeIndex = "/CPYJJFA";
          break;
        case "/cooperate":
          this.activeIndex = this.$route.path;
          break;
        case "/contact":
          this.activeIndex = "/all";
          break;
        case "/join":
          this.activeIndex = "/all";
          break;
        case "/newsdetail":
          this.activeIndex = "/news";
          break;
        case "/projectdetail":
          this.activeIndex = "/project";
          break;
      }
    },

    isShowMenuFn_XW1() {
      // console.log('悬停悬停')
      this.isShowMenu_XW = true;
      // this.isShowMenu = true
    },
    isShowMenuFn_XW2() {
      // console.log('离开')
      this.isShowMenu_XW = false;
      // this.isShowMenu = false
    },

    isShowMenuFn_GY1() {
      // console.log('离开')
      this.isShowMenu_GY = true;
    },
    isShowMenuFn_GY2() {
      // console.log('离开')
      this.isShowMenu_GY = false;
    },

    isShowMenuFn_XM1() {
      this.isShowMenu_XM = true;
    },
    isShowMenuFn_XM2() {
      this.isShowMenu_XM = false;
    },

    isShowMenuFn_CP1() {
      this.isShowMenu_CP = true;
    },
    isShowMenuFn_CP2() {
      this.isShowMenu_CP = false;
    },

    fn() {
      console.log("点我干什摸!");
    },
  },
  watch: {
    $route: "getPath",
  },

  // watch: {
  //     Top:{
  //         handler (newVal) {
  //             if (newVal >= 200) {
  //                 this.isShow = false
  //             } else {
  //                 this.isShow = true
  //             }
  //         }
  //     }
  // }
};
</script>

<style lang="less">
.t-menu--dark.t-head-menu {
  background-color: transparent !important;
  margin-top: 0 !important;
}

.scrollisshow1 {
  opacity: 0;
}
.scrollisshow2 {
  opacity: 1;
}

//.guanyuwomen {
//    padding: 0 20px;
//}

.erjicaidan.erjicaidan_cp {
  right: 24px;
}

.erjicaidan {
  //display: none;
  border-radius: 5px;
  position: absolute;
  top: 55px;
  right: -1px;
  //transition: .4s ease-in-out;
  font-size: 16px;
  padding: 0 20px;
  a {
    //padding: 20px 0;
  }
  .fgx {
    width: 100%;
    height: 1px;
    background-color: #dbdce0;
    margin-top: 2px;
  }
}

.erjiColor1 {
  box-shadow: 0px 5px 16px 0px #bcbdbde6;
  background-color: #fff;
  a {
    color: #000;
  }
}
.erjiColor2 {
  box-shadow: 0px 5px 16px 0px #bcbdbde6;
  background-color: #fff;
  a {
    color: #000;
  }
}

.bjcolor1 {
  background-color: #24292f;
}
.bjcolor2 {
  background-color: #fff;
}
.hezihezi {
  width: 100%;
  //max-width: 1920px;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  header {
    //max-width: 1914px;
    margin: 0 auto;
    user-select: none;
    //position: fixed;
    //left: 0;
    //top: 0;
    //z-index: 9999;
    transition: 0.4s ease-in-out;
    nav.container {
      height: 100px;
      align-items: center;
      //background-color: rgba(36, 41, 47);
      position: relative;
      a {
        display: inline-block;
      }
      display: flex;
      justify-content: space-between;
      h1 {
        margin: 0;
        display: inline-block;
        height: 60px;
        img {
          height: 60px;
          filter: contrast(80%)

        }
      }
      .el-menu-demo > .el-menu-item:hover {
        //height: 100%;
        background-color: transparent !important;
        color: #909399 !important;
      }
      .el-menu--horizontal > .el-submenu .el-submenu__title:hover {
        background-color: transparent !important;
        color: #909399 !important;
      }
      .el-menu.el-menu--horizontal {
        border-bottom: 0;
        transition: 1s ease-in-out;
      }
      .el-menu--horizontal > .el-menu-item {
        height: 70px;
        line-height: 70px;
        font-size: 20px;
      }
      //.el-menu--horizontal>.el-menu-item:nth-child(6) {
      //    padding: 0;
      //}
      .el-menu--horizontal > .el-submenu .el-submenu__title {
        height: 70px !important;
        line-height: 70px !important;
        font-size: 16px !important;
      }
      .el-menu--horizontal > .el-menu-item.is-active {
        border-bottom: 2px solid #909399;
        color: #909399;
      }
      .el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
        border-bottom: 2px solid #909399;
        color: #909399;
      }
      .el-menu--horizontal .el-menu-item:not(.is-disabled):focus,
      .el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
        outline: 0;
        color: #909399;
      }
      .el-menu.el-menu--popup.el-menu--popup-bottom-start {
        background-color: #fff !important;
      }
    }
  }
}

.erjicaidan {
  font-size: 20px;
}
</style>
