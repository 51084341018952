import request from '@/utils/request';

// 查询新闻列表
export function getProductList() {
    return request({
        url: '/notices/product/list',
        method: 'get',
    });
}

// 查询新闻详情
export function getProductDetail(Id) {
    return request({
        url: '/notices/product/' + Id,
        method: 'get',
    });
}
