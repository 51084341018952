<template>
  <div>

      <div class="join">
          <div class="hz">
          <div class="container">
              <div class="wz">
                  <h3>加入我们</h3>
                  <span>J O I N&nbsp;&nbsp;U S</span>
              </div>
          </div>
          </div>
      </div>

      <div class="dahezi">
      <div class="gsdt xiaohezi" v-for="(item,i) in titles" :key="i">
          <div class="container">
              <CommonCarousel data-aos="fade-up" :MinBanner="item.data" :links="'/recruit'" :titles="item" :obj="item.data" :type="i+1"></CommonCarousel>
          </div>
      </div>
      </div>

  </div>
</template>

<script>
import CommonCarousel from "@/components/CommonCarousel.vue";

import { getRecruitList } from "@/api/recruit";

export default {
    name: "JoinPage",
    components: {
        CommonCarousel,
    },
    data () {
        return {
            titles: [
                {
                    t1:'社会招聘',
                    t2:'CAMPUS',
                    data: []
                },{
                    t1:'校园招聘',
                    t2:'SOCIETY',
                    data: []
                },
            ]
        }
    },
    created() {
        this.getRecruitList()
    },
    methods: {
        async getRecruitList() {
            let res = await getRecruitList()
            // console.log(res.data.rows,'1111')
            res = res.data.rows
            let arr1 = []
            let arr2 = []
            res.forEach((item,i)=>{
                if(item.type === "1"){
                    item.pic = require('../assets/image/join01.png')
                    arr1.push(item)
                }else{
                    item.pic = require('../assets/image/join02.png')
                    arr2.push(item)
                }
            })
            this.titles[0].data = arr1
            this.titles[1].data = arr2
        }
    }
}
</script>

<style lang="less" scoped>

.gsdt {
  .container {
    padding: 50px 0 74px 0;
  }
}

.join {
    width: 100%;
    background-color: #f8f9fa;
    div.hz {
    background: url("../assets/image/jrwm-bj.png") no-repeat 0 0/100% 100%;
        max-width: 1914px;
        margin: 0 auto;
    }
    .container {
        padding-top: 180px;
        padding-bottom: 215px;
        .wz {
            h3 {
                color: #575757;
                font-size: 30px;
            }
            span {
                display: inline-block;
                margin-top: 10px;
                font-size: 20px;
                color: #353535;
            }
        }
    }
}

</style>
