<template>
  <div>

    <div class="contactus">
      <div class="hz">
        <div class="container">
          <div>
            <h3>联系我们</h3>
            <span>C O N T A C T &nbsp;&nbsp; U S</span>
          </div>
        </div>
      </div>
    </div>

    <div class="dahezi">
      <div class="xiaohezi">
        <div class="zthz">
          <div class="map">
            <div class="container">
              <el-amap
                  ref="map"
                  vid="amapDemo"
                  :center="center"
                  :zoom="zoom"
                  class="amap-demo"
              >
                <el-amap-marker
                    :position="center"
                    key="110"
                ></el-amap-marker>
              </el-amap>
            </div>
          </div>
          <div class="xin-xi">
            <div class="container">
              <ul>
                <li v-for="(item,i) in xinxiList" :key="i">
                  <img :src="item.a" />
                  <div>
                    <p>{{item.b}}</p>
                    <p>{{item.c}}</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>


        </div>
      </div>
    </div>

<!--    <img class="isImg" src="https://xdsf.cc/image/banner/3.png" />-->

  </div>
</template>

<script>
export default {
  name: "ContactPage",
  data () {
    return {
      xinxiList: [
        {
          a:require('../assets/image/lxwm-dz.png'),
          b:'公司地址',
          c:'北京市丰台区航丰路1号院时代财富天地大厦2号楼6层',
        },
        {
          a:require('../assets/image/lxwm-dh.png'),
          b:'联系方式',
          c:'固定电话:010-58090097/98',
        },
        {
          a:require('../assets/image/lxwm-yb.png'),
          b:'邮编',
          c:'邮编:100070',
        },
      ],

      zoom: 200,
      center: [116.287276,39.831537],
      searchOption: {
        // 限制搜索城市的范围
        citylimit: false,
      },
      content: '',
      inputValue: '',
      searchResult: {
        address: '',
        latitude: '',
        longitude: '',
        name: '',
        type: '',
        country: '',
        province: '',
        city: '',
        area: '',
        township: '',
        street: '',
        neighborhood: '',
        locationName: '',
      },

    }
  },
}
</script>

<style lang="less">

.isImg {
  opacity: 0;
  position: absolute;
}

.zthz {

  max-width: 1200px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  padding: 60px 0;
  overflow: hidden;
}

.map {
  .container {
    width: 900px;
    height: 600px;
    background: #ffffff;
    box-shadow: 1px 4px 10px 0px rgba(214, 214, 214, 0.5);
    border-radius: 20px;
    //border: 1px solid #f8f8f8;
    padding: 25px 30px;
    //margin-bottom: 60px;
    .amap-demo {
      width: 100%;
      height: 100%;
    }
  }
}

.xin-xi {
  z-index: 111;
  .container {
    width: auto;
    //padding: 60px 0;
    height: 100%;
    ul {
      height: 55%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      z-index: -100;
      li {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        background: #FFFFFF;
        //box-shadow: 1px 4px 10px 0px rgba(214,214,214,0.5);
        border-radius: 5px;
        padding: 20px 20px;
        width: 400px;
        transform: translateX(15PX) translateY(-2px);
        z-index: -100;
        img {
          width: 40px;
          height: 44px;
          margin-right: 32px;
        }
        div {
          p:nth-child(1) {
            font-size: 16px;
            color: #000000;
          }
          p:nth-child(2) {
            font-size: 14px;
            color: #B1B1B1;
            line-height: 26px;
          }
        }
      }
    }
  }
}

.contactus {
  width: 100%;
  background-color: #f8f9fa;
  div.hz {
    background: url("../assets/image/lxwm-bj.png") no-repeat 0 0/100% 100%;
    //max-width: 1914px;
    margin: 100px auto;
  }
  .container {
    padding-top: 120px;
    padding-bottom: 70px;
    div {
      h3 {
        font-size: 30px;
        color: #FFFFFF;
      }
      span {
        display: inline-block;
        margin-top: 10px;
        font-size: 20px;
        color: #FFFFFF;
      }
    }
  }
}

</style>
