<template>
  <div id="app">
      <CommonIndex></CommonIndex>
  </div>
</template>

<script>
import CommonIndex from "@/components/CommonIndex.vue";
export default {
    name: 'App',
    components: {
        CommonIndex
    }
}
</script>

<style lang="less">


  ::-webkit-scrollbar-track-piece {
    background: #d3dce6;
  }

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background: #99a9bf;
    border-radius: 20px;
  }


* {
  scroll-behavior: smooth;
}
#app,body{
  margin: 0;
  padding: 0;
  //user-select: none;

}
.container {
  max-width: 85%;
  margin: 0 auto;
}

  @media screen  and (max-width: 1281px) {
    .container {
      width: auto;
      margin: 0 auto;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  @media screen  and (max-width: 1000px) {
    .container {

    }
  }

a {
  color: #fff;
  display: inline-block;
  text-decoration: none;
}
h3,p {
  margin: 0;
}
ul {
  margin: 0;
  padding: 0;
}
li {
  list-style: none;
}
</style>
