<template>
    <div>

        <div class="dahezi">
        <div class="biaoti xiaohezi">
            <div class="container">
                <h3>{{ ProjectDetail.title }}</h3>
                <span>产品与解决方案</span>
                <span>></span>
                <span>{{ ProjectType }}</span>
                <span>|</span>
                <span>{{ ProjectDetail.createTime }}</span>
<!--                <span>|</span>-->
<!--                <span>{{  }}</span>-->
            </div>
        </div>
        </div>

        <div class="dahezi">
        <div class="neirong xiaohezi">
            <div class="container">
                <!--              <div  v-html="detail.newsContent"></div>-->

                <div v-html="ProjectDetail.content">
                    <p>
                        4月26日，中国有色金属加工工业协会（以下简称“加工协会”）副秘书长靳海明，铜业部主任吴琼、副主任胡亮到北京贤达四方科技有限公司（以下简称“贤达四方”）调研，受到公司总经理涂金宏、项目经理郭世杰、产品经理王刃锋、工程师刘城等的热情接待。
                    </p>
                    <p>
                        4月26日，中国有色金属加工工业协会（以下简称“加工协会”）副秘书长靳海明，铜业部主任吴琼、副主任胡亮到北京贤达四方科技有限公司（以下简称“贤达四方”）调研，受到公司总经理涂金宏、项目经理郭世杰、产品经理王刃锋、工程师刘城等的热情接待。
                        产品经理王刃锋首先介绍了公司的基本情况。贤达四方创建于1998年，逐步由传统硬件集成服务向以智慧企业应用服务转型，主要业务有：智能制造MES、智慧物流WMS+TMS、智慧园区一卡通这三大方面应用。应用一：以MES驱动的智能工厂：打造集智能物流、智能工厂、智慧园区于一体的数字化透明车间。应用二：以协同、行为管理为主的智慧园区。应用三：以物联技术手段的内部智能物流。
                        贤达四方是一家创新型信息服务企业，运用互联网、物联网、大数据、云计算等核心技术，以高端IT技术服务于生产制造行业。坚持“让数据有价值，管理更简单”的服务理念，以智能制造为核心，智慧园区、智慧物流为主体业务，致力于帮助企业真正从“制造”转型“智造”，在智慧企业方面形成了相应的产品和解决方案，为客户提供全生命周期的数字化转型服务，是国内领先的智能制造整体解决方案提供商。
                    </p>
                    <img src="../assets/image/xwxq01.jpg" alt="公司动态"/>
                    <p>
                        涂金宏总经理还介绍了企业通过MES系统可以做到以下几个方面的管理优化，提升企业的效率：
                    </p>
                    <p>
                        （1）车间资源管理：对车间人员、设备、工装、物料和工时等进行管理，保证生产正常进行，并提供资源使用情况的历史记录和实时状态信息；
                    </p>
                    <p>
                        （2）库房管理：对车间内的所有库存物资进行管理。车间内物资有自制件、外协件、外购件、刀具、工装和周转原材料等；
                    </p>
                    <p>
                        （3）生产过程管理：实现生产过程的闭环可视化控制，以减少等待时间、库存和过量生产等浪费；
                    </p>
                    <p>
                        （4）生产任务管理：包括生产任务接收与管理、任务进度展示和任务查询等；
                    </p>
                    <p>
                        （5）车间计划与排产管理：结合车间资源实时负荷情况和现有计划执行进度，能力平衡后形成优化的详细排产计划。其充分考虑到每台设备的加工能力，并根据现场实际情况随时调整。在完成自动排产后，进行计划评估与人工调整。在小批量、多品种和多工序的生产环境中，利用高级排产工具可以迅速应对紧急插单的复杂情况；
                    </p>
                    <p>（6）物料跟踪管理：通过条码技术对生产过程中的物流进行管理和追踪；</p>
                    <p>
                        （7）生产制造过程的工序检验与产品质量管理：能够实现对工序检验与产品质量过程追溯，对不合格品以及整改过程进行严格控制；
                    </p>
                    <p>
                        （8）生产监控：实现从生产计划进度和设备运转情况等多维度对生产过程进行监控，实现对车间报警信息的管理；
                    </p>
                    <p>
                        （9）统计分析：能够对生产过程中产生的数据进行统计查询，分析后形成报表，为后续工作提供参考数据与决策支持。
                    </p>
                    <p>
                        通过MES系统对以上9个方面的管理可以让企业获得如下收益：（一）缩短产品制造周期15%；（二）降低原材料库存10%；（三）降低在制品数量30%；（四）减少纸质消耗使用70%；（五）降低次品率10%；（六）减少用工成本5%；（七）降低数据汇总和分析时间40%；（八）提升人员效率15%；（九）提升设备运转率20%。
                    </p>
                    <img src="../assets/image/xwxq02.png" alt="公司动态"/>
                    <p>涂金宏总经理还就有色金属加工企业智能制造现状谈了几点认识：</p>
                    <p>
                        （1）整体自动化、信息化水平较低。有色加工行业相较于钢铁、汽车等行业发展晚、规模小，中小企业多、企业生存压力较大，对自动化、信息化投入比较谨慎；
                    </p>
                    <p>
                        （2）行业发展不均衡。上市企业资金较为充足，在产品研发和信息化、自动化的投入较高，可以开发出成本低、附加值高、市场竞争优势大的产品，在定价方面有充足的话语权，中小企业资金紧缺、在产品研发、信息化、自动化方面投入较少，大多数生产附加值低、市场竞争大的产品，企业生存压力较大；
                    </p>
                    <p>
                        （3）对信息化了解不够。大多数公司缺少专业的信息化人员，对信息化了解不够，认识不到信息化对企业能够带来哪些帮助，在系统建设后应用不好，没有产生相应的价值；
                    </p>
                    <p>
                        （4）信息孤岛难集成。由于信息化建设没有统一的规划，在上信息化系统的过程中都是单独建设的，导致一个公司会有很多个系统，操作和汇总信息的工作量很大，相互间的信息传递还是传统手工方式。
                        最后，涂金宏表示，贤达四方深耕有色行业，与合作伙伴一起开放合作，努力构建一个更加高效智能的社会，促进人与人，人与物的全面互联和融合，激发每个人在任何时间的无限机遇与潜能，推动世界的进步，目前所做的产品已经在铜铝加工领域进行了广泛的应用。
                    </p>
                    <p>
                        靳海明副秘书长对贤达四方在智能化方面做出的成绩表示赞赏，就铜加工企业当前智能化应用的现状、企业经营情况以及近期企业业务开展情况等进行了深入交流。（铜业部
                        吴琼）
                    </p>
                </div>

            </div>
        </div>
        </div>

    </div>
</template>

<script>
import { getProjectDetail } from "@/api/project";
import { setStorage, getStorage } from "@/utils/storage";
import {getNewsDetail} from "@/api/news";

export default {
    name: "ProjectDetailPage",
    data () {
        return {
            ProjectDetail:getStorage('ProjectDetail') || {},
            ProjectType: ''
        }
    },
    created() {
        let id = this.$route.query.item
        this.getProjectDetail(id)
    },
    mounted() {

    },
    methods: {
        async getProjectDetail (id) {
            let ProjectDetail = await getProjectDetail(id)
            // console.log(ProjectDetail.data.data, '-=-=-=-=')
            if(!ProjectDetail.data.data){
                let data = await getNewsDetail(id)
                this.ProjectDetail = data.data.data
                setStorage('ProjectDetail', data.data.data)
                this.ProjectTypeFn()
            }else {
                this.ProjectDetail = ProjectDetail.data.data
                setStorage('ProjectDetail', ProjectDetail.data.data)
                this.ProjectTypeFn()
            }
        },
        ProjectTypeFn(){
            let data = getStorage('ProjectDetail')
            if (data.type === "1") {
                this.ProjectType = "运营治理"
            } else if (data.type === "2") {
                this.ProjectType = "公用事业"
            } else if (data.type === "3") {
                this.ProjectType = "营区监控"
            } else if (data.type === "4"){
                this.ProjectType = "BIM"
            }else {
                this.ProjectType = "其他"
            }
        }
    }
}
</script>

<style lang="less">
//p {
//    font-size: 20px;
//    color: #b1b1b1;
//    line-height: 36px;
//    text-indent: 2em;
//}
//img {
//    position: relative;
//    transform: translateX(-50%);
//    left: 50%;
//    margin: 30px 0;
//}

.neirong {
  .container {
    box-shadow: 1px 4px 10px 0px rgba(214,214,214,0.5);
    border-radius: 20px;
    padding: 28px 28px 100px 28px;
    box-sizing: border-box;
    margin-bottom: 200px;
    div {
      p {
        font-size: 20px;
        color: #b1b1b1;
        line-height: 36px;
        text-indent: 2em;
      }
        h3 {
            color: #b1b1b1;
        }
     img {
        position: relative;
        transform: translateX(-50%);
        left: 50%;
        margin: 30px 0;
      }
    }
  }
}

.biaoti {
  .container {
    margin-top: 70px;
    padding-top: 60px;
    padding-bottom: 55px;
    h3 {
      font-size: 40px;
      color: #000000;
      margin-bottom: 30px;
    }
    span {
      font-size: 18px;
      color: #B1B1B1;
    }
    span:nth-child(3),
    span:nth-child(5) {
      margin: 0 20px;
    }

  }
}

</style>
