import request from '@/utils/request';

// 查询新闻列表
export function getNewsList() {
    return request({
        url: '/notices/news/list',
        method: 'get',
    });
}

// 查询新闻详情
export function getNewsDetail(newsId) {
    return request({
        url: '/notices/news/' + newsId,
        method: 'get',
    });
}
