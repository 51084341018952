import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import TDesign from 'tdesign-vue';
// 引入组件库的少量全局样式变量
import 'tdesign-vue/es/style/index.css';

import VueAMap from "vue-amap";

import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init({
  once: true,
  mirror: true,
  offset: 200, // offset (in px) from the original trigger point
  duration: 800, // values from 0 to 3000, with step 50ms
  useClassNames:true
});

Vue.use(TDesign);
Vue.use(VueAMap);
VueAMap.initAMapApiLoader({
  key: "dc86f085a71d4d0720320b642d61840f",
  plugin: [
    "AMap.Autocomplete", //输入提示插件
    "AMap.PlaceSearch", //POI搜索插件
    "AMap.Scale", //右下角缩略图插件 比例尺
    "AMap.OverView", //地图鹰眼插件
    "AMap.ToolBar", //地图工具条
    "AMap.Geolocation", //定位控件，用来获取和展示用户主机所在的经纬度位置
    "AMap.Geocoder", // 逆地理编码,通过经纬度获取地址所在位置详细信息
    // 根据需求选用
  ],
  uiVersion: "1.0", // 地图ui版本
  v: "1.4.4", // amap版本
});

Vue.config.productionTip = false

Vue.use(ElementUI);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
