<template>
  <div>
    <div class="dahezi">
      <div class="biaoti xiaohezi">
        <div class="container">
          <h3>{{ NewsDetail.title || "" }}</h3>
          <span>新闻动态</span>
          <span>></span>
          <span>{{ NewsDetail.type === "1" ? "公司动态" : "行业新闻" }}</span>
          <span>|</span>
          <span>{{ NewsDetail.updateTime }}</span>
        </div>
      </div>
    </div>

    <div class="dahezi">
      <div class="neirong xiaohezi">
        <div class="container">
          <!--              <div  v-html="detail.newsContent"></div>-->

          <div v-html="NewsDetail.content"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getNewsDetail } from "@/api/news";
import { setStorage, getStorage } from "@/utils/storage";
import { getProjectDetail } from "@/api/project";

export default {
  name: "NewsDetailPage",
  data() {
    return {
      NewsDetail: getStorage("NewsDetail") || {},
    };
  },
  created() {
    let Id = this.$route.query.item;
    // console.log(Id,'///////////ididid/////////')
    this.getNewsDetail(Id);
  },
  methods: {
    async getNewsDetail(Id) {
      let NewsDetail = await getNewsDetail(Id);
      // console.log(NewsDetail.data.data)
      if (!NewsDetail.data.data) {
        let data = await getProjectDetail(Id);
        this.NewsDetail = data.data.data;
        setStorage("NewsDetail", data.data.data);
      } else {
        this.NewsDetail = NewsDetail.data.data;
        setStorage("NewsDetail", NewsDetail.data.data);
      }
    },
  },
};
</script>

<style lang="less">
//p {
//    font-size: 20px;
//    color: #b1b1b1;
//    line-height: 36px;
//    text-indent: 2em;
//}
//img {
//    position: relative;
//    transform: translateX(-50%);
//    left: 50%;
//    margin: 30px 0;
//}

.neirong {
  padding-bottom: 200px;
  .container {
    box-shadow: 1px 4px 10px 0px rgba(214, 214, 214, 0.5);
    border-radius: 20px;
    padding: 28px 28px 100px 28px;
    box-sizing: border-box;
    margin-bottom: 0 !important;
    div {
      p {
        font-size: 20px;
        color: rgba(0, 0, 0, 0.7) !important;
        line-height: 36px;
        text-indent: 2em;
      }
      img {
        max-width: 500px;
        position: relative;
        transform: translateX(-50%);
        left: 50%;
        margin: 30px 0;
      }
    }
  }
}

.biaoti {
  .container {
    margin-top: 0 !important;
    padding-top: 110px !important;
    padding-bottom: 55px;
    h3 {
      font-size: 40px;
      color: #000000;
      margin-bottom: 30px;
    }
    span {
      font-size: 18px;
      color: #b1b1b1;
    }
    span:nth-child(3),
    span:nth-child(5) {
      margin: 0 20px;
    }
  }
}
</style>
