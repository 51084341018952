export const setStorage = (key, val) => {
    if (!key) return
    val=JSON.stringify(val)
    window.sessionStorage.setItem(key, val)
}

export const getStorage = (key) => {
    if (!key) return
    let val = window.sessionStorage.getItem(key)
    return JSON.parse(val)
}
