<template>
  <div>
    <!-- 产品与解决方案 -->
    <div class="product">
      <div class="hz">
        <div class="container">
          <div>
            <h3>产品与解决方案</h3>
            <span>P R O D U C T&nbsp;&nbsp;S O L U T I O N S</span>
          </div>
        </div>
      </div>
    </div>

    <!-- 下方文字与轮播图 -->
    <!-- <div class="dahezi">
      <div class="TextBanner xiaohezi">
        <div class="container" v-for="(item, i) in titles" :key="i">
          <CommonCarousel
            data-aos="fade-up"
            :min-banner="item.data"
            :titles="item"
            :obj="item.data"
            :links="'/productdetail'"
          ></CommonCarousel>
        </div>
      </div>
    </div> -->

    <!-- 目录 -->
    <div class="dahezi">
      <div class="xiaohezi">
        <div class="cp-ml">
          <ul>
            <li v-for="(item, i) in cpmlList" :key="i">
              <a
                href="javascript:;"
                :class="{ cpml_a: Aactive === i }"
                @click.prevent="cpml_aFn(i)"
                >{{ item }}</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="dahezi">
      <div class="xiaohezi">
        <div class="cp-nr">
          <div class="qiehuan" v-for="(item, i) in ProductList" :key="i">
            <div class="tupian" v-show="(i + 1) % 2 === 0 ? false : true">
              <img :src="item.pic" alt="" />
            </div>
            <div class="neirong" v-show="(i + 1) % 2 === 0 ? false : true">
              <h3>{{ item.title }}</h3>
              <p>{{ item.remark }}</p>
              <div>
                <CommonButton
                  :isShow="true"
                  :links="'/productdetail'"
                  :item="item.id"
                ></CommonButton>
              </div>
            </div>
            <div class="neirong" v-show="(i + 1) % 2 === 0 ? true : false">
              <h3>{{ item.title }}</h3>
              <p>{{ item.remark }}</p>
              <div>
                <CommonButton
                  :isShow="true"
                  :links="'/productdetail'"
                  :item="item.id"
                ></CommonButton>
              </div>
            </div>
            <div class="tupian" v-show="(i + 1) % 2 === 0 ? true : false">
              <img :src="item.pic" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CommonCarousel from "@/components/CommonCarousel.vue";
import { getProductList } from "@/api/product";
import CommonButton from "@/components/CommonButton.vue";
export default {
  name: "ProductPage",
  components: {
    CommonCarousel,
    CommonButton,
  },
  data() {
    return {
      Aactive: 0,
      cpmlList: ["智能工厂", "智慧园区", "智能物流"],
      ProductList: [],
      ProductList1: [],
      ProductList2: [],
      ProductList3: [],
      titles: [
        {
          t1: "智能工厂",
          t2: "FACTORY",
          data: [
            {
              title: "该解决方案涵盖制造协同服务管理",
              remark:
                "该解决方案涵盖制造协同服务管理、数字化车间管理平台、生产规划及排程系统、工业设备设施运维管理解决方案等服务内容，能面向核心制造企业提供上下游协同，精益生产管理，助力企业实现协同制造、柔性制造。",
              pic: "http://114.55.32.174/image/upload/06/07/20230607025604A008.jpg",
            },
            {
              title: "数字化车间管理平台",
              remark:
                "该解决方案涵盖制造协同服务管理、数字化车间管理平台、生产规划及排程系统、工业设备设施运维管理解决方案等服务内容，能面向核心制造企业提供上下游协同，精益生产管理，助力企业实现协同制造、柔性制造。",
              pic: "http://114.55.32.174/image/upload/06/07/20230607025604A008.jpg",
            },
            {
              title: "生产规划及排程系统",
              remark:
                "该解决方案涵盖制造协同服务管理、数字化车间管理平台、生产规划及排程系统、工业设备设施运维管理解决方案等服务内容，能面向核心制造企业提供上下游协同，精益生产管理，助力企业实现协同制造、柔性制造。",
              pic: "http://114.55.32.174/image/upload/06/07/20230607025604A008.jpg",
            },
          ],
        },
        {
          t1: "智慧园区",
          t2: "PARK",
          data: [
            {
              title: "工业设备设施运维管理解决方案等服务内容",
              remark:
                "该解决方案涵盖制造协同服务管理、数字化车间管理平台、生产规划及排程系统、工业设备设施运维管理解决方案等服务内容，能面向核心制造企业提供上下游协同，精益生产管理，助力企业实现协同制造、柔性制造。",
            },
            {
              title: "能面向核心制造企业提供上下游协同",
              remark:
                "该解决方案涵盖制造协同服务管理、数字化车间管理平台、生产规划及排程系统、工业设备设施运维管理解决方案等服务内容，能面向核心制造企业提供上下游协同，精益生产管理，助力企业实现协同制造、柔性制造。",
            },
            {
              title: "精益生产管理",
              remark:
                "该解决方案涵盖制造协同服务管理、数字化车间管理平台、生产规划及排程系统、工业设备设施运维管理解决方案等服务内容，能面向核心制造企业提供上下游协同，精益生产管理，助力企业实现协同制造、柔性制造。",
            },
          ],
        },
        {
          t1: "智能物流",
          t2: "LOGISTICS",
          data: [
            {
              title: "柔性制造",
              remark:
                "该解决方案涵盖制造协同服务管理、数字化车间管理平台、生产规划及排程系统、工业设备设施运维管理解决方案等服务内容，能面向核心制造企业提供上下游协同，精益生产管理，助力企业实现协同制造、柔性制造。",
            },
            {
              title: "助力企业实现协同制造",
              remark:
                "该解决方案涵盖制造协同服务管理、数字化车间管理平台、生产规划及排程系统、工业设备设施运维管理解决方案等服务内容，能面向核心制造企业提供上下游协同，精益生产管理，助力企业实现协同制造、柔性制造。",
            },
            {
              title: "以MES驱动的智能工厂",
              remark:
                "该解决方案涵盖制造协同服务管理、数字化车间管理平台、生产规划及排程系统、工业设备设施运维管理解决方案等服务内容，能面向核心制造企业提供上下游协同，精益生产管理，助力企业实现协同制造、柔性制造。",
            },
          ],
        },
      ],
    };
  },
  created() {
    this.getProductList();
  },

  watch: {
    Aactive: function (newVal, oldVal) {
      if (newVal === 0) {
        this.ProductList = this.ProductList1;
      } else if (newVal === 1) {
        this.ProductList = this.ProductList2;
      } else if (newVal === 2) {
        this.ProductList = this.ProductList3;
      }
    },
  },

  methods: {
    cpml_aFn(i) {
      this.Aactive = i;
    },

    async getProductList() {
      let res = await getProductList();
      // console.log(res.data.rows,'product product')
      let data = res.data.rows;
      let arr1 = [];
      let arr2 = [];
      let arr3 = [];
      data.forEach((item) => {
        // console.log(item,'iiiiiiiiiiiiiiiiiiiii')
        if (item.type === "0") {
          arr1.push(item);
        } else if (item.type === "1") {
          arr2.push(item);
        } else {
          arr3.push(item);
        }
      });
      this.ProductList = arr1;
      this.ProductList1 = arr1;
      this.ProductList2 = arr2;
      this.ProductList3 = arr3;
      if (this.$route.query.type === "ZHYQ") {
        this.ProductList = arr2;
        this.Aactive = 1;
      } else if (this.$route.query.type === "ZNWL") {
        this.ProductList = arr3;
        this.Aactive = 2;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.cp-nr {
  max-width: 1200px;
  margin: 0 auto;
  padding-bottom: 100px;
  .qiehuan {
    padding-bottom: 30px;
    margin-bottom: 30px;
    display: flex;
    border-bottom: 1px solid #eee;
    .tupian {
      img {
        max-width: 550px;
      }
    }
    .neirong {
      padding-bottom: 0px;
      padding: 0 40px;
      h3 {
        font-size: 20px;
        margin-bottom: 20px;
      }
      p {
        line-height: 26px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
      }
      div {
        float: right;
        margin-top: 30px;
      }
    }
  }
}

.cpml_a {
  background-color: rgba(238, 238, 238, 1);
}
.cp-ml {
  padding: 40px 0 20px 0;
  max-width: 1200px;
  margin: 0 auto;
  ul {
    display: flex;

    li {
      a {
        font-size: 20px;
        color: #454545;
        padding: 20px;
        transition: all 0.5s;
        // background-color: rgba(238, 238, 238, 0);
      }
    }
  }
}

.TextBanner {
  .container {
    padding-top: 50px;
    padding-bottom: 58px;
  }
}

.product {
  width: 100%;
  background-color: #f8f9fa;
  div.hz {
    background: url("../assets/image/cpyjjfa-bj.png") no-repeat 0 0/100% 100%;
    //max-width: 1914px;
    margin: 0 auto;
  }
  .container {
    padding-top: 180px;
    padding-bottom: 215px;
    div {
      h3 {
        font-size: 30px;
        color: #ffffff;
      }
      span {
        display: inline-block;
        margin-top: 10px;
        font-size: 20px;
        color: #ffffff;
      }
    }
  }
}
</style>
