<template>
  <div>

      <div class="wscn-http404-container">
          <div class="wscn-http404">
              <div class="pic-404">
                  <img class="pic-404__parent" src="../assets/image/404.png" alt="404">
                  <img class="pic-404__child left" src="../assets/image/404_cloud.png" alt="404">
                  <img class="pic-404__child mid" src="../assets/image/404_cloud.png" alt="404">
                  <img class="pic-404__child right" src="../assets/image/404_cloud.png" alt="404">
              </div>
              <div class="bullshit">
                  <div class="bullshit__oops">
                      404错误!
                  </div>
                  <div class="bullshit__headline">
                      找不到网页！
                  </div>
                  <div class="bullshit__info">
                      <p>对不起，您正在寻找的页面不存在。尝试检查URL的错误，然后按浏览器上的刷新按钮或尝试在我们的应用程序中找到其他内容。</p>
                  </div>
                  <router-link to="/" class="bullshit__return-home">返回首页</router-link>
              </div>
          </div>
      </div>

  </div>
</template>

<script>
export default {
    name: "404Page"
}
</script>

<style>
.wscn-http404-container {
    /*transform: translate(-50%, -50%);*/
    /*position: absolute;*/
    /*top: 40%;*/
    /*left: 50%;*/
    padding-top: 270px;
    padding-bottom: 200px;
}

.wscn-http404 {
    /*position: relative;*/
    /*width: 1200px;*/
    /*padding: 0 50px;*/
    /*overflow: hidden;*/
    display: flex;
    justify-content: center;
}

/*.wscn-http404 .pic-404 {*/
/*    position: relative;*/
/*    float: left;*/
/*    width: 600px;*/
/*    overflow: hidden;*/
/*}*/

.wscn-http404 .pic-404__parent {
    /*width: 100%;*/
    position: relative;
}

.wscn-http404 .pic-404__child {
    position: absolute;
}

.wscn-http404 .pic-404__child.left {
    width: 80px;
    top: 200px;
    left: 800px;
    opacity: 0;
    animation-name: cloudLeft;
    animation-duration: 2s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    animation-delay: 1s;
}

.wscn-http404 .pic-404__child.mid {
    width: 46px;
    top: 200px;
    left: 1000px;
    opacity: 0;
    animation-name: cloudMid;
    animation-duration: 2s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    animation-delay: 1.2s;
}

.wscn-http404 .pic-404__child.right {
    width: 62px;
    top: 430px;
    left: 1200px;
    opacity: 0;
    animation-name: cloudRight;
    animation-duration: 2s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    animation-delay: 1s;
}
@keyframes cloudLeft {
    0% {
        top: 200px;
        left: 800px;
        opacity: 0;
    }
    20% {
        top: 200px;
        left: 800px;
        opacity: 1;
    }
    80% {
        top: 300px;
        left: 600px;
        opacity: 1;
    }
    100% {
        top: 300px;
        left: 600px;
        opacity: 0;
    }
}

@keyframes cloudMid {
    0% {
        top: 200px;
        left: 1000px;
        opacity: 0;
    }
    20% {
        top: 200px;
        left: 1000px;
        opacity: 1;
    }
    70% {
        top: 300px;
        left: 800px;
        opacity: 1;
    }
    100% {
        top: 300px;
        left: 800px;
        opacity: 0;
    }
}

@keyframes cloudRight {
    0% {
        top: 430px;
        left: 1200px;
        opacity: 0;
    }
    20% {
        top: 430px;
        left: 1200px;
        opacity: 1;
    }
    80% {
        top: 530px;
        left: 1000px;
        opacity: 1;
    }
    100% {
        top: 530px;
        left: 1000px;
        opacity: 0;
    }
}

.wscn-http404 .bullshit {
    /*position: relative;*/
    /*float: left;*/
    width: 320px;
    padding: 30px 0;
    overflow: hidden;
}

.wscn-http404 .bullshit__oops {
    font-size: 32px;
    font-weight: bold;
    line-height: 40px;
    color: #1482f0;
    opacity: 0;
    margin-bottom: 20px;
    animation-name: slideUp;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
}

.wscn-http404 .bullshit__headline {
    font-size: 20px;
    line-height: 24px;
    color: #222;
    font-weight: bold;
    opacity: 0;
    margin-bottom: 10px;
    animation-name: slideUp;
    animation-duration: 0.5s;
    animation-delay: 0.1s;
    animation-fill-mode: forwards;
}

.wscn-http404 .bullshit__info {
    font-size: 18px;
    line-height: 28px;
    color: grey;
    opacity: 0;
    margin-bottom: 30px;
    animation-name: slideUp;
    animation-duration: 0.5s;
    animation-delay: 0.2s;
    animation-fill-mode: forwards;
}

.wscn-http404 .bullshit__return-home {
    display: inline-block;
    width: 110px;
    height: 36px;
    position: relative;
    left: 60%;
    background: #1482f0;
    border-radius: 100px;
    text-align: center;
    color: #ffffff;
    opacity: 0;
    font-size: 14px;
    line-height: 36px;
    cursor: pointer;
    animation-name: slideUp;
    animation-duration: 0.5s;
    animation-delay: 0.3s;
    animation-fill-mode: forwards;
    text-decoration: none;
}
@keyframes slideUp {
    0% {
        transform: translateY(60px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

/*# sourceMappingURL=404.css.map */

@media screen and (max-width: 980px) {
    .wscn-http404 {
        position: relative;
        overflow: hidden;
        width: 100%;
        padding: 0;
        display: flex;
        flex-direction: column;
    }

    .wscn-http404 .bullshit {
        margin-top: 10%;
        float: none;
        width: 100%;
        padding: 0;
    }

    .wscn-http404-container {
        top: 30%;
    }

    .bullshit > .bullshit__info {
        font-size: 20px;
    }
    .bullshit__info p{
        line-height: 2rem
    }

}
</style>
