<template>
  <div>

      <div class="project">
          <div class="hz">
          <div class="container">
              <div>
                  <h3>项目案例</h3>
                  <span>P R O G E C T&nbsp;&nbsp;C A S E</span>
              </div>
          </div>
          </div>
      </div>

      <div class="dahezi">
        <div class="selected xiaohezi" style="max-width: 100%;">
            <div class="container">
                <h3>精选案例</h3 >
                <span>P R O G E C T&nbsp;&nbsp;C A S E</span>
            </div>
        </div>
      </div>

      <div class="dahezi">
      <div class="two-menu xiaohezi" style="max-width: 100%;">
          <div class="container">
              <ul>

                  <li @click.prevent="TwoMenuActives(1)">
                      <a :style="{color:TwoMenuActive===1?'#407BFF':'#2D2D2D'}">
                        有色加工
                          <img v-show="TwoMenuActive===1" src="../assets/image/xmal-2jcd.png" />
                      </a>
                  </li>
                  <li @click.prevent="TwoMenuActives(2)">
                      <a :style="{color:TwoMenuActive===2?'#407BFF':'#2D2D2D'}">
                        有色冶炼
                          <img v-show="TwoMenuActive===2" src="../assets/image/xmal-2jcd.png" />
                      </a>
                  </li>
                <li @click.prevent="TwoMenuActives(3)">
                  <a :style="{color:TwoMenuActive===3?'#407BFF':'#2D2D2D'}">
                    锂电材料
                    <img v-show="TwoMenuActive===3" src="../assets/image/xmal-2jcd.png" />
                  </a>
                </li>
                <li @click.prevent="TwoMenuActives(4)">
                  <a :style="{color:TwoMenuActive===4?'#407BFF':'#2D2D2D'}">
                    其他
                    <img v-show="TwoMenuActive===4" src="../assets/image/xmal-2jcd.png" />
                  </a>
                </li>

                  <!--
                  <li @click.prevent="TwoMenuActives(1)">
                      <router-link :to="{path:'/project/menu1',query:{ProjectList}}">
                          运营治理
                          <img v-show="TwoMenuActive===1" src="../assets/image/xmal-2jcd.png" />
                      </router-link>
                  </li>
                  <li @click.prevent="TwoMenuActives(2)">
                      <router-link :to="{path:'/project/menu2',query:{ProjectList}}">
                          公用事业
                          <img v-show="TwoMenuActive===2" src="../assets/image/xmal-2jcd.png" />
                      </router-link>
                  </li>
                  <li @click.prevent="TwoMenuActives(3)">
                      <router-link :to="{path:'/project/menu3',query:{ProjectList}}">
                          营区监控
                          <img v-show="TwoMenuActive===3" src="../assets/image/xmal-2jcd.png" />
                      </router-link>
                  </li>
                  <li @click.prevent="TwoMenuActives(4)">
                      <router-link :to="{path:'/project/menu4',query:{ProjectList}}">
                          B I M
                          <img v-show="TwoMenuActive===4" src="../assets/image/xmal-2jcd.png" />
                      </router-link>
                  </li>
                  -->

              </ul>
          </div>
      </div>
      </div>

      <div class="dahezi">
      <div class="project-content xiaohezi">
          <div class="hz">
          <div class="container">
              <MenuOne :project-list="ProjectList"></MenuOne>
          </div>
          </div>
      </div>
      </div>

  </div>
</template>

<script>
import MenuOne from "@/views/ProjectChild/MenuOne.vue";
import { getProjectList } from "@/api/project";

export default {
    name: "ProjectPage",
    components: {
        MenuOne
    },
    data () {
        return {
            TwoMenuActive: 1,
            color: '#407BFF',
            ProjectList: [],
            list:[]

        }
    },
    created() {
        this.getProjectList()
    },
    methods: {
        TwoMenuActives (val) {
            this.TwoMenuActive = val
            let data = this.list
            let arr = []
            data.forEach((item)=>{
                if(item.type === ''+val){
                    arr.push(item)
                }
            })
            this.ProjectList = arr
        },
        async getProjectList () {
            let ProjectList = await getProjectList()
            this.list = ProjectList.data.rows
            let data = ProjectList.data.rows
            let arr = []
            data.forEach((item)=>{
                if(item.type === '1'){
                    arr.push(item)
                }
            })
            this.ProjectList = arr
        }
    }
}
</script>

<style lang="less" scoped>

.project-content {
    width: 100%;
    background-color: #F8F9FA;
    div.hz {
        //max-width: 1914px;
        margin: 0 auto;
        background-color: #F8F9FA;
    }
    .container {

    }
}

.two-menu {
    .container {
        ul {
            display: flex;
            justify-content: center;
            li {
                margin: 0 100px;
                .router-link-active {
                    color: #407BFF;
                }
                a {
                    display: flex;
                    flex-direction: column;
                    padding: 16px 5px 0 5px;
                    width: 80px;
                    font-size: 18px;
                    color: #2D2D2D;
                    text-align: center;
                    img {
                        margin-top: 16px;
                    }
                }
                a:hover {
                    color: #407BFF;
                }
            }
        }
    }
}

.selected {
  .container {
      text-align: center;
      padding-top: 50px;
      padding-bottom: 32px;
    h3 {
      color: #454545;
      font-size: 30px;
        margin-bottom: 10px;
        font-weight: 400;
    }
    span {
      font-size: 18px;
      color: #DEDEDE;
    }
  }
}

.project {
    width: 100%;
    background-color: #f8f9fa;
    div.hz {
        background: url("../assets/image/xmal-bj.png") no-repeat 0 0/100% 100%;
        //max-width: 1914px;
        margin: 0 auto;
    }
    .container {
        padding-top: 180px;
        padding-bottom: 215px;
        div {
            h3 {
                font-size: 30px;
                color: #FFFFFF;
            }
            span {
                display: inline-block;
                margin-top: 10px;
                font-size: 20px;
                color: #FFFFFF;
            }
        }
    }
}
</style>
