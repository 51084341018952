<template>
  <div class="LinkButton">



      <router-link :to="{path:links,query:{ item:item,type:type }}" v-show="isShow" class="btn1">
            <t-button :disabled="disabled" variant="outline" ghost>{{ text }}</t-button>

<!--          <div>{{ text }}<img src="@/assets/image/right1.png" /></div>-->
      </router-link>
      <router-link :to="{path:links,query:{ item:item,type:type }}" v-show="!isShow" class="btn2">
            <t-button :disabled="disabled" variant="outline" ghost>{{ text }}</t-button>

<!--          <div>{{ text }}<img src="@/assets/image/right2.png" /></div>-->
      </router-link>
  </div>
</template>

<script>
export default {
    name: "CommonButton",
    props: {
        isShow: {
            type: Boolean,
        },
        text: {
            type: String,
            default: '查看详情'
        },
        links: {
            type: String,
            default: '/'
        },
        item: {
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        },
        type: {
            type: Number
        }
    }
}
</script>

<style lang="less">

.btn1 {
  .t-button--variant-outline.t-button--ghost {
    color: #000;
    border-color: #000;
  }
}
.btn2 {
  .t-button--variant-outline.t-button--ghost {
    color: var(--td-text-color-anti);
    border-color: var(--td-text-color-anti);
  }
}

.t-button--variant-outline.t-button--ghost {
  width: 190px;
  height: 50px;
  font-size: 16px;
}

//.LinkButton {
//    a {
//      background-color: rgba(102, 153, 204,0);
//      transition: .1s ease-in-out;
//        div {
//            color: #979797;
//            border: 1px solid #979797;
//            padding: 12px 30px;
//            font-size: 18px;
//            img {
//                margin-left: 15px;
//            }
//        }
//    }
//    a:hover {
//      background-color: rgba(102, 153, 204,.8);
//      div {
//        color: #fff;
//      }
//    }
//}

</style>
