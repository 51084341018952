import Vue from 'vue'
import VueRouter from 'vue-router'
import HomePage from "@/views/HomePage.vue";
import NewsPage from "@/views/NewsPage.vue";
import ProductPage from "@/views/ProductPage.vue";
import ProjectPage from "@/views/ProjectPage.vue";
import MenuOne from "@/views/ProjectChild/MenuOne.vue";
import MenuTwo from "@/views/ProjectChild/MenuTwo.vue";
import MenuThree from "@/views/ProjectChild/MenuThree.vue";
import MenuFour from "@/views/ProjectChild/MenuFour.vue";
import CooperatePage from "@/views/CooperatePage.vue";
import ContactPage from "@/views/ContactPage.vue";
import JoinPage from "@/views/JoinPage.vue";
import NewsDetailPage from "@/views/NewsDetailPage.vue";
import RecruitPage from "@/views/RecruitPage.vue";
import RecruitDetailPage from "@/views/RecruitDetailPage.vue";
import ProjectDetailPage from "@/views/ProjectDetailPage.vue";
import ProductDetailPage from "@/views/ProductDetailPage.vue";

import notfound from '@/views/404Page.vue';

Vue.use(VueRouter)

const routes = [
  { path: "/", redirect: "/home" },
  {
    path: '/home',
    name: 'HomePage',
    component: HomePage,
    meta: { title:'贤达四方-首页' }
  },
  {
    path: '/news',
    name: 'NewsPage',
    component: NewsPage,
    meta: { title:'贤达四方-新闻动态' }
  },
  {
    path: '/project',
    name: 'ProjectPage',
    component: ProjectPage,
    meta: { title:'贤达四方-项目案例' }
  },
  {
    path: '/product',
    name: 'ProductPage',
    component: ProductPage,
    meta: { title:'贤达四方-产品与解决方案' }
  },
  {
    path: '/productdetail',
    name: 'ProductDetailPage',
    component: ProductDetailPage,
    meta: { isColor: true,title:'贤达四方-产品与解决方案详情' }
  },
  {
    path: '/cooperate',
    name: 'CooperatePage',
    component: CooperatePage,
    meta: { title:'贤达四方-合作伙伴' }
  },
  {
    path: '/contact',
    name: 'ContactPage',
    component: ContactPage,
    meta: { title:'贤达四方-联系我们' }
  },
  {
    path: '/join',
    name: 'JoinPage',
    component: JoinPage,
    meta: { isColor: true,title:'贤达四方-加入我们' }
  },
  {
    path: '/newsdetail',
    name: 'NewsDetailPage',
    component: NewsDetailPage,
    meta: { isColor: true,title:'贤达四方-新闻动态详情' }
  },
  {
    path: '/recruit',
    name: 'RecruitPage',
    component: RecruitPage,
    meta: { isColor: true,title:'贤达四方-招聘信息' }
  },
  {
    path: '/recruitdetail',
    name: 'RecruitPage',
    component: RecruitDetailPage,
    meta: { isColor: true,title:'贤达四方-招聘信息详情' }
  },
  {
    path: '/projectdetail',
    name: 'ProjectDetailPage',
    component: ProjectDetailPage,
    meta: { isColor: true,title:'贤达四方-项目案例详情' }
  },

  {
    path: '/404',
    name: '404Page',
    component: notfound,
    meta: { isColor: true,title:'贤达四方-404' }
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/404'
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  window.scrollTo({ top: 0, behavior:"instant"})
  document.title = to.meta.title
  next()
})
router.beforeResolve((to, from, next) => {
  window.scrollTo({ top: 0, behavior:"instant"})
  next()
})

export default router
