import { render, staticRenderFns } from "./CommonCarousel.vue?vue&type=template&id=58093582&scoped=true&"
import script from "./CommonCarousel.vue?vue&type=script&lang=js&"
export * from "./CommonCarousel.vue?vue&type=script&lang=js&"
import style0 from "./CommonCarousel.vue?vue&type=style&index=0&id=58093582&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58093582",
  null
  
)

export default component.exports