<template>
  <div>
    <div class="dhz">
      <div class="shang">
        <h3>{{ titles.t1 }}</h3>
        <span>{{ titles.t2 }}</span>
      </div>
      <div class="xia">
        <div class="wz">
          <h3>{{ dataObj.title || ''}}</h3>
          <p>{{ dataObj.remark }}</p>
          <CommonButton
            :isShow="true"
            :links="links"
            :item="dataObj.id"
            :type="type"
          ></CommonButton>
        </div>
        <div class="tp">
          <!-- <el-carousel height="298px" indicator-position="outside">
                      <el-carousel-item v-for="(item,i) in MinBanner" :key="i">
                          <h3 class="small">
                              <img :src="item.pic" />
                          </h3>
                      </el-carousel-item>
                  </el-carousel> -->
            <img :src="bannerLis[type-1]" />

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CommonButton from "@/components/CommonButton.vue";
export default {
  name: "CommonCarousel",
  props: ["links", "obj", "titles", "MinBanner", "type"],
  data() {
    return {
      bannerLis: [
        require("../assets/image/join01.png"),
        require("../assets/image/join02.png"),
        // require('../assets/image/joinxdlbt02.jpg'),
      ],
      dataObj: {},
    };
  },
  components: {
    CommonButton,
  },
  created() {
    this.setObj();
  },
  methods: {
    setObj() {
      let timer = setInterval(() => {
        // console.log(this.$props.obj,'ooooooooooooo')
        this.dataObj = this.$props.obj[0];
        if (this.$props.obj.length > 0) {
          clearInterval(timer);
          let dataArr = this.$props.obj;
          let i = 0;
          let timer1 = setInterval(() => {
            this.dataObj = dataArr[i];
            i++;
            if (i === dataArr.length) {
              i = 0;
            }
            // console.log(this.dataObj)
          }, 5000);
        }
      }, 100);
      setTimeout(() => {
        clearInterval(timer);
      }, 2000);
    },
  },
};
</script>

<style lang="less" scoped>
.el-carousel__item h3 {
  //color: #475669;
  font-size: 14px;
  //opacity: 0.75;
  line-height: 150px;
  margin: 0;
}
.el-carousel__item {
  border-radius: 0 20px 0 0;
}
.el-carousel--horizontal {
  border-radius: 0 20px 0 0;
}

.dhz {
  .shang {
    text-align: center;
    h3 {
      margin-bottom: 10px;
      font-size: 30px;
      color: #454545;
      font-weight: 400;
    }
    span {
      display: inline-block;
      margin-bottom: 40px;
      font-size: 18px;
      color: #dedede;
    }
  }
  .xia {
    //padding-bottom: 74px;
    box-shadow: 1px 4px 10px 0px rgba(214, 214, 214, 0.5);
    border-radius: 20px;
    border: 1px solid #f8f8f8;
    display: flex;
    justify-content: space-between;
    background: #ffffff;
    .wz {
      width: 586px;
      padding: 70px 0 75px 84px;
      h3 {
        font-size: 24px;
        color: #000000;
        margin-bottom: 25px;
        font-weight: 400;

        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
      }
      p {
        font-size: 16px;
        color: #b1b1b1;
        line-height: 26px;
        padding-right: 100px;
        overflow: hidden;
        margin-bottom: 30px;

        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
      }
      //p:nth-child(3) {
      //    margin-bottom: 17px;
      //}
    }
    .tp {
      width: 674px;
      height: auto;
      background: #f5f7fa;

      img {
        width: 674px;
        height: auto;
        display: block;
        border-radius: 0 0 20px 0;
      }
    }
  }
}
</style>
