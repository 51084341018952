<template>
  <div class="xinwen_mulu">
    <ul>
      <li v-for="item in newsList" :key="item.id">
        <div class="tupian">
          <router-link :to="{ path: '/newsdetail', query: { item: item.id } }"
            ><img :src="item.pic" alt=""
          /></router-link>
        </div>
        <div class="neirong">
          <h3>
            <router-link
              :to="{ path: '/newsdetail', query: { item: item.id } }"
              >{{ item.title }}</router-link
            >
          </h3>
          <span
            ><span>{{ item.createTime }}</span> /
            <span>{{ item.type === "1" ? "公司动态" : "行业新闻" }}</span></span
          >
          <p>
            {{ item.remark }}
          </p>
          <div>
            <CommonButton
              :isShow="true"
              :links="'/newsdetail'"
              :item="item.id"
            ></CommonButton>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import CommonButton from "@/components/CommonButton.vue";

export default {
  name: "newsChild",
  props: ["newsList"],
  components: {
    CommonButton,
  },
};
</script>

<style lang="less" scoped>
.xinwen_mulu {
  ul {
    li {
      margin-bottom: 30px;
      border-bottom: 1px solid #eee;
      padding: 0 40px;
      display: flex;
      justify-content: space-between;
      .tupian {
        height: 100%;
        a {
          img {
            width: 200px;
            // position: relative;
            // top: 50%;
            // left: 50%;
            // transform: translate(-50%, 70%);
          }
        }
      }
      .neirong {
        height: auto;
        padding-bottom: 30px;
        padding-left: 20px;
        h3 {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          a {
            color: #000;
          }
        }
        span {
          line-height: 40px;
        }
        p {
          line-height: 24px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
        }
        div {
          margin-top: 10px;
          float: right;
        }
      }
    }
  }
}
</style>