<template>
  <div>
    <!-- 新闻动态 -->
    <div class="dahezi">
      <div class="news">
        <div class="hz">
          <div class="container">
            <div class="wz">
              <h3>新闻动态</h3>
              <span>N E W S</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 公司动态 -->
    <!-- <div class="dahezi">
      <div class="trends">
        <div class="container">
          <CommonCarousel
            data-aos="fade-up"
            :min-banner="NewsList1"
            :titles="titles"
            :links="'/newsdetail'"
            :obj="NewsList1"
          ></CommonCarousel>
        </div>
      </div>
    </div> -->

    <!-- 行业新闻 -->
    <!-- <div class="dahezi">
      <div class="industry xiaohezi">
        <div class="container">
          <div class="shang" data-aos="fade-up">
            <h3>行业新闻</h3>
            <span>D Y N A M I C</span>
          </div>
          <div class="xia" data-aos="fade-up">
            <ul>
              <li v-for="(item, i) in NewsList2" :key="i">
                <router-link
                  :to="{ path: '/newsdetail', query: { item: item.id } }"
                >
                  <div class="chuan">
                    <img :src="item.pic" />
                    <div>
                      <p>{{ item.title }}</p>
                      <p class="miaoshu">{{ item.remark }}</p>
                    </div>
                  </div>
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div> -->

    <div class="dahezi" style="background-color: #fff;">
      <div class="xiaohezi">
        <div class="xinwenmulu">
          <div class="mulu">
            <ul>
              <li>
                <a href="javascript:;" @click="muluFn_gs"
                  ><span v-show="isShow_ml">➣</span>公司动态</a
                >
              </li>
              <div class="fgx"></div>
              <li>
                <a href="javascript:;" @click="muluFn_hy"
                  ><span v-show="!isShow_ml">➣</span>行业新闻</a
                >
              </li>
            </ul>
          </div>
          <div class="xinwen">
            <newsChild :newsList="newsList"></newsChild>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CommonCarousel from "@/components/CommonCarousel.vue";
import newsChild from "@/views/newsChild/newsChild.vue";

import { getNewsList } from "@/api/news";

export default {
  name: "NewsPage",
  components: {
    CommonCarousel,
    newsChild,
  },
  data() {
    return {
      newsList: [],
      NewsList1: [], // 公司动态
      NewsList2: [], // 行业新闻
      isShow_ml: true, // 显示哪一个目录的小图标
      titles: {
        t1: "公司动态",
        t2: "DYNAMIC",
      },
    };
  },
  created() {
    this.getNewsList();
  },
  watch: {
    isShow_ml: function (newVal, oldVal) {
      if (newVal) {
        this.newsList = this.NewsList1;
      } else {
        this.newsList = this.NewsList2;
      }
    },
  },
  methods: {
    async getNewsList() {
      let res = await getNewsList();
      if (res.data && res.data.code === 200) {
        let NewsList = res.data.rows;
        // console.log(NewsList)
        let arr1 = [];
        let arr2 = [];
        NewsList.forEach((item) => {
          if (item.type === "1") {
            arr1.push(item);
          } else {
            arr2.push(item);
          }
        });
        this.NewsList1 = arr1;
        this.NewsList2 = arr2;
        if (this.$route.query.type === "HYXW") {
          this.newsList = arr2;
          this.isShow_ml = false;
        } else {
          this.newsList = arr1;
          this.isShow_ml = true;
        }
        // console.log(arr1,'1111111111111111111')
        // console.log(arr2,'2222222222222222222')
      }
    },

    muluFn_gs() {
      this.isShow_ml = true;
    },
    muluFn_hy() {
      this.isShow_ml = false;
    },
  },
};
</script>

<style lang="less" scoped>
.xinwenmulu {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 0;
  display: flex;
  justify-content: space-between;
  .mulu {
    ul {
      border: 1.5px solid #eee;
      border-radius: 5px;
      li {
        padding: 10px 80px;
        a {
          font-size: 20px;
          color: #000;
          span {
            display: inline-block;
            font-size: 20px;
            transform: translateX(-5px) translateY(0px);
          }
        }
      }
      .fgx {
        height: 1.5px;
        width: 100%;
        background-color: #eee;
      }
    }
  }
  .xinwen {
    flex: 1;
  }
}

@media screen and (max-width: 1247px) {
  .industry {
    .container {
      padding-left: 100px;
      padding-right: 100px;
      .xia {
        ul {
          justify-content: space-between;
          li {
            margin-right: 50px !important;
          }
          li:nth-child(2n) {
            margin-right: 0 !important;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1016px) {
  .industry {
    .container {
      padding-left: 50px;
      padding-right: 50px;
      .xia {
        ul {
          justify-content: space-between;
          li {
            margin-right: 50px !important;
          }
          li:nth-child(2n) {
            margin-right: 0 !important;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 916px) {
  .industry {
    .container {
      .xia {
        ul {
          justify-content: center;
          li {
            margin-right: 0 !important;
          }
        }
      }
    }
  }
}

.industry {
  .container {
    padding-bottom: 50px;
    .shang {
      text-align: center;
      h3 {
        margin-bottom: 10px;
        font-size: 30px;
        color: #454545;
        font-weight: 400;
      }
      span {
        display: inline-block;
        margin-bottom: 40px;
        font-size: 18px;
        color: #dedede;
      }
    }
    .xia {
      ul {
        display: flex;
        flex-wrap: wrap;
        li {
          margin-right: 30px;
          a {
            width: 380px;
            height: 410px;
            background: #ffffff;
            box-shadow: 1px 2px 6px 0px rgba(214, 214, 214, 0.5);
            margin-bottom: 30px;
            transition: 0.3s ease-in-out;
            .chuan {
              img {
                width: 380px;
                height: 174px;
              }
              div {
                padding: 30px 27px 19px 19px;
                p:nth-child(1) {
                  font-size: 16px;
                  color: #454545;
                  line-height: 26px;
                }
                .miaoshu {
                  line-height: 26px;
                  color: #aaaaaa;
                  font-size: 14px;
                  padding-right: 15px;

                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 5;
                }
              }
            }
          }
          a:hover {
            transform: scale(1.01) translateY(-4px);
            box-shadow: 0 2px 0 rgba(0, 0, 0, 0.05),
              0 3px 10px rgba(0, 0, 0, 0.1), 0 5px 20px rgba(0, 0, 0, 0.2);
          }
        }
        li:nth-child(3n) {
          margin-right: 0;
        }
      }
    }
  }
}

.trends {
  //max-width: 1914px;
  margin: 0 auto;
  background-color: #fff;
  .container {
    padding: 50px 0 74px 0;
  }
}

.news {
  width: 100%;
  div.hz {
    background: url("../assets/image/news.png") no-repeat 0 0/100% 110%;
    //max-width: 1914px;
    margin: 0 auto;
  }
  .container {
    padding-top: 180px;
    padding-bottom: 215px;
    .wz {
      h3 {
        color: #ffffff;
        font-size: 30px;
      }
      span {
        display: inline-block;
        margin-top: 10px;
        font-size: 20px;
        color: #c9c9c9;
      }
    }
  }
}
</style>
