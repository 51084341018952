import request from '@/utils/request';

// 查询全部招聘信息
export function getRecruitList() {
    return request({
        url: '/notices/recruit/list',
        method: 'get',
    });
}

// 查询招聘详情
export function getRecruitDetail(recruitId) {
    return request({
        url: '/notices/recruit/' + recruitId,
        method: 'get',
    });
}
