import request from '@/utils/request';

// 查询所有项目案例
export function getProjectList() {
    return request({
        url: '/notices/project/list',
        method: 'get',
    });
}

// 根据id查询项目案例详情
export function getProjectDetail(id) {
    return request({
        url: '/notices/project/' + id,
        method: 'get',
    });
}
