<template>
  <div>
      <div class="content">
          <div class="zuo">

            <div class="you">
              <img :src="item.pic">
            </div>

            <div class="neirong">
                <h3>{{item.title}}</h3>
                <p>{{ item.remark }}</p>
                <CommonButton class="lbutton" :is-show="true"  :links="'/projectdetail'" :item="item.id"></CommonButton>
            </div>
          </div>
      </div>
  </div>
</template>

<script>
import CommonButton from "@/components/CommonButton.vue";
export default {
  name: "CommonProject",
  components: {
      CommonButton
  },
  props: ['item']
}
</script>

<style lang="less" scoped>
.content {
    //display: flex;
    //justify-content: space-between;
    //align-items: center;
    .zuo {
        //flex: 1;
        display: flex;
        background: #FFFFFF;
        box-shadow: 1px 4px 10px 0px rgba(214,214,214,0.5);
        border-radius: 20px;
        padding: 50px;
        .neirong {
            flex: 1;
            padding-bottom: 0;
            h3 {
                color: #000000;
                font-size: 24px;
                font-weight: 400;
                margin-bottom: 25px;
                display: inline-block;
            }
            p {
                line-height: 26px;
                font-size: 16px;
                color: #B1B1B1;
                margin-bottom: 16px;
                //padding-right: 95px;
                display: inline-block;

                overflow:hidden;
                text-overflow:ellipsis;
                display:-webkit-box;
                -webkit-box-orient:vertical;
                -webkit-line-clamp:5;
            }
            .LinkButton {
                display: inline-block;
            }
        }

    }
    .you {
        width: 200px;
        .t-image__wrapper {
            width: 200px
        }
        .t-image {
            width: 200px;
            //height: 373px;
        }
    }
    .neirong {
        margin-left: 50px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .lbutton {
            //margin-left: 660px;
        }
    }
}

img {
  width: 100%
}
</style>
