<template>

    <div class="dahezi">
    <div class="zhaopinxinxi xiaohezi">
        <div class="container">

            <div class="zuo">
<!--                <div class="sousuo">-->
<!--                    <img src="../assets/image/zpxx-search.png" />-->
<!--                    <input type="text" placeholder="搜索职位关键字" />-->
<!--                    <button>搜索职位</button>-->
<!--                </div>-->
<!--                <div class="qbzw">全部职位 (共{{ RecruitList.length }}个)</div>-->
<!--                <div class="fgx"></div>-->

                <div class="jutizhiwei">
                    <div class="hz">
                        <div class="di-yi">
                            <h3>{{ RecruitDetail.title }}</h3>
                            <span>{{ RecruitDetail.salary }}</span>
                        </div>
                        <div class="di-er">
                            <div>
                                <span>社会招聘</span>
                                <span>|</span>
                                <span>全职</span>
                            </div>
                            <span>{{ RecruitDetail.createTime }} 发布</span>
                        </div>
                    </div>
                    <div class="di-san">
                        <div class="neirong">
                            <div v-html="RecruitDetail.content"></div>
                            <!--                              <p>任职资格</p>-->
                            <!--                              <p>1、机械、机电、信息、计算机工程等相关专业，专科以上学历；</p>-->
                            <!--                              <p> 2、3年以上制造业企业从事设计、工艺、制造信息化相关工作，或者3年以上从事MES系统\自动化生产线\智能物流\智能工厂技术-->
                            <!--                                  咨询支持工作，有过智能工厂规划工作经验者优先；</p>-->
                            <!--                              <p> 3、较强的学习能力，新环境的适应能力，能承受一定的工作压力；</p>-->
                            <!--                              <p> 4、思维清晰，具有极强的语言表达能力，工作细致认真；</p>-->
                            <!--                              <p> 5、具有较强的流程梳理、分析、优化能力；</p>-->
                            <!--                              <p> 6、善于与人沟通，具备良好的解决问题技巧及沟通、组织、协调能力。</p>-->
                            <!--                              <p>任职资格</p>-->
                            <!--                              <p>1、机械、机电、信息、计算机工程等相关专业，专科以上学历；</p>-->
                            <!--                              <p> 2、3年以上制造业企业从事设计、工艺、制造信息化相关工作，或者3年以上从事MES系统\自动化生产线\智能物流\智能工厂技术-->
                            <!--                                  咨询支持工作，有过智能工厂规划工作经验者优先；</p>-->
                            <!--                              <p> 3、较强的学习能力，新环境的适应能力，能承受一定的工作压力；</p>-->
                            <!--                              <p> 4、思维清晰，具有极强的语言表达能力，工作细致认真；</p>-->
                            <!--                              <p> 5、具有较强的流程梳理、分析、优化能力；</p>-->
                            <!--                              <p> 6、善于与人沟通，具备良好的解决问题技巧及沟通、组织、协调能力。</p>-->
                        </div>
<!--                        <div class="anniu">-->
<!--                            <div>立即投递</div>-->
<!--                            <div>-->
<!--                                <img src="../assets/image/shoucang.png" />-->
<!--                                收藏-->
<!--                            </div>-->
<!--                        </div>-->
                    </div>
                </div>

            </div>

            <div class="you"></div>

        </div>
    </div>
    </div>

</template>

<script>
import {getRecruitDetail} from "@/api/recruit";
import {getStorage, setStorage} from "@/utils/storage";

export default {
    name: "RecruitDetailPage",
    data () {
        return {
            RecruitDetail: {}
        }
    },
    created() {
        // console.log(this.$route.query.item)
        let Id = this.$route.query.item
        this.getRecruitDetail(Id)
    },
    methods: {
        async getRecruitDetail (Id) {
            if (Id) {
                setStorage('RDid', Id)
                let RecruitDetail = await getRecruitDetail(Id)
                // console.log(RecruitDetail.data.data)
                this.RecruitDetail = RecruitDetail.data.data
            } else {
                let Id = getStorage('RDid')
                let RecruitDetail = await getRecruitDetail(Id)
                // console.log(RecruitDetail.data.data)
                this.RecruitDetail = RecruitDetail.data.data
            }
        }
    }
}
</script>

<style lang="less">
.zhaopinxinxi {
    .container {
        padding-top: 62px;
        padding-bottom: 70px;
        .zuo {

            .qbzw {
                font-size: 20px;
                color: #252525;
                margin-top: 53px;
                margin-bottom: 26px;
                padding-left: 40px;
            }
            .fgx {
                height: 1px;
                background: #F0F0F0;
            }

            .jutizhiwei {
                .hz {
                    padding: 57px 0 34px 0;
                    border-bottom: 1.4px solid #f0f0f0;
                }
                .di-yi{
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 16px;
                    h3{
                        font-size: 28px;
                        color: #252525;
                        font-weight: 700;
                    }
                    span{
                        font-size: 26px;
                        color: #FF9B0A;
                    }
                }
                .di-er{
                    display: flex;
                    justify-content: space-between;
                    div {
                        span {
                            font-size: 16px;
                            color: #252525;
                        }
                        span:nth-child(2) {
                            margin: 0 24px;
                        }
                    }
                }
                .di-san{
                    transition: .3s ease-in-out;
                    .neirong {
                        padding: 35px 0 54px 0;
                        &>div>p {
                            font-size: 16px;
                            color: #575757;
                            line-height: 30px;

                        }
                    }
                    .anniu {
                        display: flex;
                        div:nth-child(1){
                            padding: 10px 38px;
                            background: #407BFF;
                            border-radius: 4px;
                            color: #FFFFFF;
                            font-size: 16px;
                            margin-right: 34px;
                        }
                        div:nth-child(2){
                            padding: 10px 20px;
                            border: 1px solid #E9E9E9;
                            border-radius: 4px;
                            box-sizing: border-box;
                            font-size: 16px;
                            color: #575757;
                            img {
                                margin-right: 12px;
                                vertical-align: middle;
                            }
                        }
                        div:nth-child(3) a{
                            color: #575757;
                            line-height: 44px;
                            margin-left: 310px;
                        }
                        div:nth-child(3) a:hover {
                            color: #407BFF;
                        }
                    }
                    .shouqi {
                        text-align: center;
                        padding: 20px 0;
                    }
                }
            }

        }
        .you {

        }
    }
}
</style>
